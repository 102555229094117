import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import Card from 'react-bootstrap/Card';
import '../../../css/card.css';
import {
    reportDefinitionIcons, definitionsByType, getDefinitionIconsByFacility, updateDefinitionIconsByFacility, insertDefinitionIconByFacility,
    resetDefinitionIconsByFacility, deleteDefinitionIcon
} from '../../../common/services/ReportService';

import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import Dropdown, { MenuItem } from '@trendmicro/react-dropdown';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';

import { Modal } from 'react-bootstrap';
import { userAssociatedFacilities } from '../../../common/services/ApiService';

import { toast, confirm } from '@rickylandino/react-messages';
import { Select } from 'antd';

class ManagePrintIcons extends Component {
    constructor(props) {
        super(props);

        this.state = {
            definitionIcons: [],
            definitionsByType: {},
            loaded: false,
            userAssociatedFacilities: [],
            formFields: {
                facilityID: ''
            },
            showAddModal: false,
            newIconDefinition: {
                definitionName: 'Select a definition',
                facilityID: '',
                printIconName: ''
            }
        }

        this.alive = false;
    }

    componentDidMount() {
        this.alive = true;

        combineLatest(
            reportDefinitionIcons,
            definitionsByType,
            userAssociatedFacilities
        ).pipe(takeWhile(() => this.alive)).subscribe(([rdi, dbt, af]) => {
            if (rdi?.length > 0 && dbt && af?.length > 0) {

                let defaultFacility = af.find(f => f.defaultFacility === 1)?.facilityModel?.facilityID || af[0].facilityModel?.facilityID;

                this.setState({
                    definitionIcons: rdi,
                    definitionsByType: dbt,
                    userAssociatedFacilities: af,
                    loaded: true,
                    formFields: {
                        facilityID: this.state.formFields.facilityID === '' ? defaultFacility : this.state.formFields.facilityID
                    }
                });
            }
        });
    }

    componentWillUnmount() {
        this.alive = false;
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ formFields: { ...this.state.formFields, [name]: value } }, () => {
            getDefinitionIconsByFacility(value);
        });
    }

    handleDefinitionSelection = (eventKey, event) => {
        if (eventKey) {
            let splitStr = eventKey.split(", ");
            let keyName = splitStr[0];
            let guid = splitStr[1];
            let reportDefinitionIconID = splitStr[2];

            let definitionSelected = this.state.definitionsByType[keyName].find(item => item.definitionMasterID === guid);

            let definitionIcons = this.state.definitionIcons;
            let itemToChange = definitionIcons.findIndex(def => def.reportDefinitionIconModel.reportDefinitionIconID === reportDefinitionIconID);

            definitionIcons[itemToChange].reportDefinitionMasterModel = definitionSelected;
            definitionIcons[itemToChange].reportDefinitionIconModel.definitionMasterID = definitionSelected.definitionMasterID;
            definitionIcons[itemToChange].isDirty = true;

            this.setState({
                definitionIcons
            });
        }
        
    }

    handleSubmit = () => {
        let listToUpdate = this.state.definitionIcons.filter(i => i.isDirty).map(i => (i.reportDefinitionIconModel));

        updateDefinitionIconsByFacility(listToUpdate).then(data => {
            if (data) {

                toast.success("Icon definitions have been updated.");
            } else {
                toast.error("Something went wrong");
            }
        }).catch(error => {
            toast.error("Something went wrong");
        });
    }

    toggleModal = () => {
        if (!this.state.showAddModal) {
            this.setState({ newIconDefinition: { ...this.state.newIconDefinition, facilityID: this.state.formFields.facilityID } });
        } else {
            this.setState({
                newIconDefinition: {
                    definitionName: 'Select a definition',
                    facilityID: '',
                    printIconName: ''
                }
            });
        }
        this.setState({
            showAddModal: !this.state.showAddModal
        });
    }

    handleNewDefinitionSelection = (eventKey, event) => {
        if (eventKey) {
            let splitStr = eventKey.split(", ");
            let keyName = splitStr[0];
            let guid = splitStr[1];

            let definitionSelected = this.state.definitionsByType[keyName].find(item => item.definitionMasterID === guid);

            let newIconDefinition = this.state.newIconDefinition;
            newIconDefinition.definitionName = definitionSelected.definitionName;
            newIconDefinition.definitionMasterID = definitionSelected.definitionMasterID;

            this.setState({ newIconDefinition });
        }
    }

    handleNewDefinitionChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ newIconDefinition: { ...this.state.newIconDefinition, [name]: value } });
    }

    handleNewDefinitionSubmit = () => {

        let newIconDefinition = this.state.newIconDefinition;

        if (!newIconDefinition.definitionMasterID ||
            !newIconDefinition.facilityID ||
            !newIconDefinition.printIconName) {
            toast.error("Please fill in all required information");
        } else {
            newIconDefinition.iconGroup = 'Letters';

            let name = newIconDefinition.printIconName;

            name = name.toLowerCase().match(/[A-Z0-9]+/ig).map(function (word, i) {
                return word[0].toUpperCase() + word.slice(1); // return newly formed string
            }).join("");

            newIconDefinition.printIconName = "LettersTab." + name;

            insertDefinitionIconByFacility(newIconDefinition).then(data => {

                toast.success("Icon definition has been added.");

                this.setState({
                    newIconDefinition: {
                        definitionName: 'Select a definition',
                        facilityID: '',
                        printIconName: ''
                    },
                    showAddModal: false
                }, () => reportDefinitionIcons.next(data));



            }).catch(error => {
                toast.error("Something went wrong");
            });
        }
    }

    resetDefaultDefinitions = () => {
        let facilityName = this.state.userAssociatedFacilities.find(f => f.facilityModel.facilityID === this.state.formFields.facilityID)?.facilityModel?.facilityName;

        confirm({
            title: "You are about reset the print icon definitions for " + facilityName,
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {

                let postdata = {
                    uniqueID: this.state.formFields.facilityID
                };

                resetDefinitionIconsByFacility(postdata).then(data => {

                    toast.success("Icon definitions have been reset.");

                    this.setState({
                        newIconDefinition: {
                            definitionName: 'Select a definition',
                            facilityID: '',
                            printIconName: ''
                        },
                        showAddModal: false
                    }, () => reportDefinitionIcons.next(data));

                }).catch(error => {
                    toast.error("Something went wrong");
                });

                return 0;
            } else {
                return 0;
            }
        });

        //SmartMessageBox(
        //    {
        //        title: "You are about reset the print icon definitions for " + facilityName,
        //        content:
        //            "Are you sure you would like to proceed?",
        //        buttons: "[No][Yes]"
        //    },
        //    (ButtonPressed) => {
        //        if (ButtonPressed === "Yes") {
        //            let postdata = {
        //                uniqueID: this.state.formFields.facilityID
        //            };

        //            resetDefinitionIconsByFacility(postdata).then(data => {

        //                toast.success("Icon definitions have been reset.");

        //                this.setState({
        //                    newIconDefinition: {
        //                        definitionName: 'Select a definition',
        //                        facilityID: '',
        //                        printIconName: ''
        //                    },
        //                    showAddModal: false
        //                }, () => reportDefinitionIcons.next(data));



        //            }).catch(error => {
        //                toast.error("Something went wrong");
        //            });
        //            return 0;
        //        }
        //        if (ButtonPressed === "No") {
        //            window.history.pushState(null, null, null);
        //            return 0;
        //        }
        //    }
        //);
    }

    deleteIconDefinition = (definition, idx) => {

        confirm({
            title: "You are about delete this print icon definition",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {

                deleteDefinitionIcon(definition).then(data => {

                    toast.success("Icon definition has been deleted.");

                    let definitionIcons = this.state.definitionIcons;

                    definitionIcons.splice(idx, 1);

                    this.setState({
                        definitionIcons
                    });

                }).catch(error => {
                    toast.error("Something went wrong");
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    handleFacilityChange = (selectedFacilityID) => {
        this.setState({ formFields: { ...this.state.formFields, facilityID: selectedFacilityID } }, () => {
            getDefinitionIconsByFacility(selectedFacilityID);
        });
    }

    render() {
        let { Option } = Select;

        let th = this;

        function nameFormatter(cell, row) {
            let text = cell.split('.')[1];
            var result = text.replace(/([A-Z])/g, " $1");
            var finalResult = result.charAt(0).toUpperCase() + result.slice(1);

            return (
                <span>{finalResult}</span>
            );
        }

        function deleteFormatter(cell, row) {
            return (
                <i id='delete' className='far fa-trash-alt hover fa-125x color-pink'></i>
            );
        }


        function dropdownFormatter(cell, row, rowIndex, formatExtraData) {
            let definitionsByType = formatExtraData.defByType;
            return (
                <div>
                    <Dropdown
                        onSelect={(eventKey, event) => {
                            th.handleDefinitionSelection(eventKey, event);
                        }}
                        className="mw-50 custom-nested-dropdown" 
                    >
                        <Dropdown.Toggle title={cell} className="w-100" />
                        <Dropdown.Menu className="custom-dropdown-menu">
                            {Object.keys(definitionsByType).map(function (key) {
                                return (
                                    <MenuItem key={key} className="sub-menu-item">
                                        {key}
                                        {definitionsByType[key].filter(item => item.facilityID && item.facilityID === th.state.formFields.facilityID).map((item, idx1) => {
                                            return idx1 === 0 &&
                                                <MenuItem header className="color-pink" key={idx1}>Facility Definitions</MenuItem>
                                        })}
                                        
                                        {definitionsByType[key].filter(item => item.facilityID && item.facilityID === th.state.formFields.facilityID).map((item, idx) => 
                                                <MenuItem className="hoverable" key={idx} eventKey={key + ", " + item.definitionMasterID + ", " + row.reportDefinitionIconModel.reportDefinitionIconID}>{item.definitionName}</MenuItem>
                                        )}

                                        {definitionsByType[key].filter(item => !item.facilityID).map((item, idx1) => {
                                            return idx1 === 0 &&
                                                <MenuItem header className="color-pink" key={idx1}>Global Definitions</MenuItem>
                                        })}

                                        {definitionsByType[key].filter(item => !item.facilityID).map((item, idx) => 
                                            <MenuItem className="hoverable" key={idx} eventKey={key + ", " + item.definitionMasterID + ", " + row.reportDefinitionIconModel.reportDefinitionIconID}>{item.definitionName}</MenuItem>
                                        )}
                                    </MenuItem>
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            );
        }

        const columns = [{
            dataField: 'reportDefinitionIconModel.iconGroup',
            text: 'Icon Location'
        },{
            dataField: 'reportDefinitionIconModel.printIconName',
            text: 'Icon Name',
            formatter: nameFormatter
        }, {
                dataField: 'reportDefinitionMasterModel.definitionName',
            text: 'Selected Definition',
                formatter: dropdownFormatter,
                formatExtraData: {
                    defByType: this.state.definitionsByType
            },
        }, {
            dataField: 'dumy1',
            isDummyField: true,
            text: 'Delete Icon Definition',
            formatter: deleteFormatter,
            align: 'center',
            headerAlign: 'center'
        }];

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            hideSelectColumn: true
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                if (e.target.id === 'delete') {
                    this.deleteIconDefinition(row.reportDefinitionIconModel, rowIndex);
                } 
            }
        };

        let defByType = this.state.definitionsByType;


        return (
            <Fragment>
                {this.state.loaded &&
                    <Fragment>
                    <div className="row">
                    <div className="form-group col-lg-3">
                            <label className="form-label">Selected Facility</label>
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={this.handleFacilityChange}
                                className="form-control-custom w-100"
                                bordered={false}
                                value={this.state.formFields.facilityID || ''}
                                showSearch
                                filterOption={(input, option) => (option.children[0] + option.children[1]).toLowerCase().includes(input.toLowerCase())}
                            >
                                {this.state.userAssociatedFacilities.map((fac, idx) => <Option key={idx} key={fac.facilityModel.facilityID} value={fac.facilityModel.facilityID}>{fac.facilityModel.facilityNumber && fac.facilityModel.facilityNumber + " - "}{fac.facilityModel.facilityName}</Option>)}
                            </Select>
                        </div>
                        </div>
                    <Card className="pinkCard">
                        <Card.Header><span>Print Icons <i className="far fa-plus-square fa-15x hover float-right" onClick={this.toggleModal} /></span>
                        </Card.Header>
                        <Card.Body>
                            <div className="fullTable">

                                <BootstrapTable keyField='reportDefinitionIconModel.reportDefinitionIconID'
                                    data={this.state.definitionIcons}
                                    columns={columns}
                                    selectRow={selectRow}
                                    rowEvents={rowEvents}
                                    condensed />

                            </div>
                        </Card.Body>
                        
                    </Card>
                    <button className="btn btn-submit" onClick={this.handleSubmit}>Save</button>
                    <button className="btn btn-warning float-right" onClick={this.resetDefaultDefinitions}>Reset Default Definitions</button>
                    </Fragment>
                }

                <Modal size='lg' show={this.state.showAddModal} onHide={this.toggleModal} backdrop='static'>
                    <Modal.Header>
                        <h1>Add Icon Definition</h1>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="panel-content">
                            <div className="row">
                                <div className="form-group col-lg-4 col-12">
                                    <label className="form-label">Icon Definition Name <span className="color-pink">*</span></label>
                                    <input className="form-control-custom" value={this.state.newIconDefinition.printIconName || ''} name="printIconName" onChange={this.handleNewDefinitionChange} />
                                </div>
                                
                                <div className="form-group col-lg-4 col-12">
                                    <label className="form-label">Selected Facility <span className="color-pink">*</span></label>
                                    <select className="form-control-custom" value={this.state.newIconDefinition.facilityID || ''} name="facilityID" onChange={this.handleNewDefinitionChange}>
                                        {this.state.userAssociatedFacilities.map((f, idx) => <option key={idx} value={f.facilityModel.facilityID}>{f.facilityModel.facilityName}</option>)}
                                    </select>
                                </div>

                                <div className="form-group col-lg-4 col-12">
                                    <label className="form-label">Select a Definition <span className="color-pink">*</span></label>
                                    <Dropdown
                                        onSelect={(eventKey, event) => {
                                            this.handleNewDefinitionSelection(eventKey, event);
                                        }}
                                        className="w-100 custom-nested-dropdown"
                                    >
                                        <Dropdown.Toggle title={this.state.newIconDefinition.definitionName} className="w-100 form-control-custom" />
                                        <Dropdown.Menu>
                                            {Object.keys(this.state.definitionsByType).map(function (key) {
                                                return (
                                                    <MenuItem key={key}>
                                                        {key}
                                                        {defByType[key].filter(item => item.facilityID && item.facilityID === th.state.newIconDefinition.facilityID).map((item, idx1) => {
                                                            return idx1 === 0 &&
                                                                <MenuItem header key={idx1}>Facility Definitions</MenuItem>
                                                        })}

                                                        {defByType[key].filter(item => item.facilityID && item.facilityID === th.state.newIconDefinition.facilityID).map((item, idx) =>
                                                            <MenuItem key={idx} eventKey={key + ", " + item.definitionMasterID}>{item.definitionName}</MenuItem>
                                                        )}

                                                        {defByType[key].filter(item => !item.facilityID).map((item, idx1) => {
                                                            return idx1 === 0 &&
                                                                <MenuItem header key={idx1}>Global Definitions</MenuItem>
                                                        })}

                                                        {defByType[key].filter(item => !item.facilityID).map((item, idx) =>
                                                            <MenuItem key={idx} eventKey={key + ", " + item.definitionMasterID}>{item.definitionName}</MenuItem>
                                                        )}
                                                    </MenuItem>
                                                );

                                                return (
                                                    <MenuItem key={key}>
                                                        {key}
                                                        {defByType[key].map((item, idx) =>
                                                            <MenuItem key={idx} eventKey={key + ", " + item.definitionMasterID}>{item.definitionName}</MenuItem>
                                                        )}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                                <div className="form-group col-12">
                                    <label className="form-label">Icon Description</label>
                                    <textarea className="form-control-custom" name="iconDescription" onChange={this.handleNewDefinitionChange}></textarea>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="row">
                            <div className="form-group col-12 padding-25-10">
                                <button className="btn btn-submit" onClick={this.handleNewDefinitionSubmit}>Save</button>
                                <button className="btn btn-outline-default margin-left-15" id="closeModal" onClick={this.toggleModal}>Cancel</button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}

export default withRouter(ManagePrintIcons);