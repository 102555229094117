import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Axios from '../../config/axios';
import Globals from '../../config/globals';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { ColumnToggle } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Moment from 'moment';
import $ from 'jquery';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ReactLoading from 'react-loading';
import { Fragment } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import AddPatientPane from './AddPatientPane';
import PatientSearchForm from './PatientSearchForm';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { Empty, message } from 'antd';

import { getTop50Patients, loadingPatientResults, patientResults, getPatientInformationByIDNew, downloadPatientList, getJustCategoryByFacility } from '../../common/services/ApiService';
import { takeWhile } from 'rxjs/operators';
import { getAllPatientExamInfoByPatient } from '../../common/services/ExamService';

import { toast } from '@rickylandino/react-messages';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import MaskedInput from 'react-text-mask';

import NumberFormat from 'react-number-format';
import { handlePrintHx } from '../../common/services/ReportService';

class PatientsHome extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {

        if (Globals.selectedFacility.facilityID && (nextProps.selectedFacility !== prevState.selectedFacility)) {
            return {
                selectedFacility: nextProps.selectedFacility,
                facilitySelected: true
            }
        }
        return null;
    }

    constructor(props) {
        super(props);

        var nowDate = new Date();
        this.mwlDate = nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate();

        this.state = {
            patients: [],
            selectedPatient: null,
            insuranceCompany: null,
            loading: true,
            loadPatient: true,
            facilitySelected: false,
            selectedFacility: {},
            formFields: {
                modality: '',
                studyDate: new Date(this.mwlDate)
            },
            showAddPatient: false,
            noPatients: true,
            showCustomColumns: false,
            initialSearch: true
        }
        this._isMounted = false;
        this.getPatient = this.getPatient.bind(this);
        this.selectPatient = this.selectPatient.bind(this);
        this.getInsuranceCompany = this.getInsuranceCompany.bind(this);

        this.patients = [];

    }

    componentDidMount() {
        this._isMounted = true;
        loadingPatientResults.next(true);
        
        $(document).ready();
        $(document.body).on('keydown', this.handleKeyDown);

        if (Globals.selectedFacility.facilityID) {
            this.setState({
                facilitySelected: true,
                selectedFacility: Globals.selectedFacility
            });
        }


        patientResults.pipe(takeWhile(() => this._isMounted)).subscribe(data => {
            if (data?.length > 0 || Globals.searchDirty) {
                this.setState({
                    loading: false,
                    patients: data,
                    selectedPatient: data?.length > 0 ? data[0] : null,
                    noPatients: data?.length > 0 ? false : true
                });

                loadingPatientResults.next(false);
            } else {
                this.getTop50();
            }
            
        });

        loadingPatientResults.pipe(takeWhile(() => this._isMounted)).subscribe(data => {
            this.setState({
                loading: data
            });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
        $(document.body).off('keydown', this.handleKeyDown);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selectedFacility.facilityID !== prevState.selectedFacility.facilityID) {
            this.setState({
                loading: true
            });
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;


        this.setState({ formFields: { ...this.state.formFields, [name]: value } });

    }

    handleDateChange = (name, date) => {
        this.setState({ formFields: { ...this.state.formFields, [name]: date } }, () => {
            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                this.isDirty = true;  // dirty
            }

        });
    }

    handleKeyDown = (event) => {
        
        if (event.keyCode === 13 /*enter*/) {
            if (!event.target.offsetParent || !event.target.offsetParent === 'div.search-div.dropdown') {
                getPatientInformationByIDNew(this.state.selectedPatient.patientModel.patientID).then(data => {

                    this.props.history.push({
                        pathname: '/patient-dashboard',
                        state: { selectedObject: data.patientInformation, examInfo: data.examInfo, filteredCategoryList: this.state.selectedPatient.categoryList }
                    });
                }); 
            }           
        }
        if (event.keyCode === 40 /*down*/) {
            event.preventDefault();
            // Get the current page scroll position
            //var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            //var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

            //    // if any scroll is attempted, set this to the previous value
            //    window.onscroll = function () {
            //        window.scrollTo(scrollLeft, scrollTop);
            //    };

            let i = this.state.patients.findIndex(obj => obj.patientModel.patientID === this.state.selectedPatient.patientModel.patientID);
            let selectedPatient = this.state.patients[i + 1]
            this.setState({
                selectedPatient
            });

            //window.onscroll = function () { };
        }

        if (event.keyCode === 38 /*up*/) {
            event.preventDefault();
            // Get the current page scroll position
            //scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            //scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,

            //    // if any scroll is attempted, set this to the previous value
            //    window.onscroll = function () {
            //        window.scrollTo(scrollLeft, scrollTop);
            //    };

            let i = this.state.patients.findIndex(obj => obj.patientModel.patientID === this.state.selectedPatient.patientModel.patientID);
            if (i !== 0) {
                let selectedPatient = this.state.patients[i - 1]
                this.setState({
                    selectedPatient
                });
            }

            //window.onscroll = function () { };
        }
    }

    getTop50 = () => {
        this.setState({
            loading: true
        });

        getTop50Patients().then(data => {
            patientResults.next(data);
            loadingPatientResults.next(false);
        });
    }

    getPatient(patient) {
        this.setState({
            selectedPatient: patient,
            loadPatient: false
        });
    }

    selectPatient(patient) {
        if (patient) {
            let patientID = patient.patientModel.patientID;

            getAllPatientExamInfoByPatient(patientID).then(data => {

                this.props.history.push({
                    pathname: '/patient-dashboard',
                    state: { selectedObject: patient, examInfo: data }
                });

            }).catch(error => {
                console.log(error);
            });
        }

    }

    getInsuranceCompany(insuranceID) {

        let postdata = {};
        postdata.uniqueID = insuranceID;
        Axios.post(`/api/GetInsuranceCompanyByID`, postdata
        ).then(response => {
            this.setState({
                insuranceCompany: response.data,
                loadPatient: false
            });
        }).catch(error => {
            console.log(error);
        });
    }

    addToWorklist = () => {
        let patient = this.state.selectedPatient.patientModel;

        let modalitySplit = this.state.formFields.modality.split(" ");

        let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

        let dicom_PatientModel = {
            patientID: patient.mrn,
            patientGUID: patient.patientID,
            patientsFirstName: patient.fName,
            patientsLastName: patient.lName,
            patientsBirthDate: new Date(patient.dob),
            //patientsAge: '',
            patientsSex: 'F',
            serverPartitionGUID: patient.facilityID,
            responsibleOrganization: this.state.selectedPatient.facilityModel.facilityName,
            referringPhysiciansFirstName: this.state.selectedPatient.providerModel && this.state.selectedPatient.providerModel.fName,
            referringPhysiciansLastName: this.state.selectedPatient.providerModel && this.state.selectedPatient.providerModel.lName,
            npi: this.state.selectedPatient?.providerModel?.npi || null,
            sentToModalityYN: '1',
            mirth: '0',
            modality: modalitySplit[1] === 'Mammogram' ? 'MG' : 'US',
            studyDate: this.state.formFields.studyDate,
            accessionNo: null,
            status: 'SC',
            examClass: modalitySplit[0],
            userID: userInfo.userId
        }

        Axios.post(`/api/InsertDicomPatient`, dicom_PatientModel
        ).then(response => {

            this.setState({
                formFields: {
                    modality: '',
                    studyDate: new Date(this.mwlDate)
                },
                showPane: false
            });

            //simulates body click to hide popover
            document.body.click();

            if (response.data === 'Patient has an incomplete exam on file') {
                toast.error(response.data)
            } else {
                toast.success(response.data)
            }

            
        }).catch(error => {
            console.log(error);
        });
    }

    updatePatientsLoading = () => {
        this.setState({
            loading: true
        });
    }

    updatePatients = () => {
        this.setState({
            loading: true
        }, () => {
            this.setState({
                patients: Globals.patients,
                loading: false,
                noPatients: Globals.patients.length > 0 ? false : true
            });
        });
       

    }

    toggleCustomizeColumns = () => {
        this.setState({
            showCustomColumns: !this.state.showCustomColumns
        });

    }

    handleBulkPrint = () => {

        const q = Axios.defaults.baseURL + "api/RenderPatientResults";

        var form = document.createElement("form");
        form.target = "_blank";
        form.method = "POST";
        form.action = q;
        form.style.display = "none";

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "patientResults";
        input.value = JSON.stringify(this.state.patients);
        form.appendChild(input);

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }

    handlePatientExport = () => {
        const key = "loading";
        message.loading({ content: 'Exporting Patients...', key, duration: 0 });
        let fileName = "patient-results_" + Moment().format('YYYYMMDDHm') + '.xlsx';

        downloadPatientList(this.state.patients, fileName).then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = fileName;//"File.xlsx";
            //document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);

            this.setState({
                downloading: false
            })
        }).catch(error => {
            console.log("Error");
        });
    }

    handleMwlDateChange = (date) => {
        let mwlTimeFormat = "MMMM d, yyyy h:mm aa";
        if (date.toString().includes('00:00:00'))
            mwlTimeFormat = "MMMM d, yyyy";

        this.setState({ formFields: { ...this.state.formFields, studyDate: date }, mwlTimeFormat });
    }

    render() {
        function fullNameFormatter(cell, row) {
            return (
                <span>{row.providerModel?.fName && row.providerModel.fName} {row.providerModel?.lName && row.providerModel.lName}</span>
            );
        }

        function checkboxFormatter(cell, row) {
            return (
                <div className="">
                    <div className="custom-control custom-checkbox custom-control-inline">
                        {(cell === "Yes" || cell === "High") &&
                            <i className="fas fa-check fa-125x color-pink text-center"></i>
                        }
                    </div>
                </div>
            );
        }

        function folderFormatter(cell, row) {
            return (
                <i id="openChart" className="far fa-folder-open fa-125x color-pink text-center"></i>
            );
        }

        const handleInputChange = (e) => {
            this.handleInputChange(e);
        }

        const addToWorklist = () => {
            this.addToWorklist();
        }

        const emptyFormFields = () => {
            let formFields = {
                modality: '',
                studyDate: new Date(this.mwlDate)
            };
            this.setState({
                formFields
            });
        }

        const handleMwlDateChange = (date) => {
            this.handleMwlDateChange(date);
        }

        const mwlFormatter = (cell, row, index, formatExtraData) => {

            let disableBtn = formatExtraData.st.formFields.modality === '' ? true : false;
            let studyDate = formatExtraData.st.formFields.studyDate;
            let mwlTimeFormat = formatExtraData.st.mwlTimeFormat;

            var modalityList = [];

            row.categoryList.forEach(item => {
                switch (item.category) {
                    case 'SCRMAM':
                        modalityList.push("Screening Mammogram");
                        break;
                    case 'DXMAM':
                        modalityList.push("Diagnostic Mammogram");
                        break;
                    case 'SCRUS':
                        modalityList.push("Screening Ultrasound");
                        break;
                    case 'DXUS':
                        modalityList.push("Diagnostic Ultrasound");
                        break;
                    default:
                        break;
                }
            });

            if (modalityList.length === 0) return;
            
            return (
                <OverlayTrigger
                    trigger={"click"}
                    rootClose
                    key='top'
                    placement='bottom'
                    onExit={emptyFormFields}
                    overlay={
                        <Popover id='popover' className="mwlPopover">
                            <Popover.Title as="h3">Select a Modality</Popover.Title>
                            <Popover.Content>
                                <div>
                                    {modalityList.map((mod, idx) => (
                                        <div className="custom-control custom-radio" key={idx}>
                                            <input type="radio" name="modality" className="custom-control-input" value={mod} onChange={handleInputChange} />
                                            <span className="custom-control-label">{mod}</span>
                                        </div>
                                    ))}
                                </div>
                                
                                <hr />

                                <div>
                                    <div className="form-label">Study Date</div>
                                    <DatePicker
                                        showTimeSelect
                                        dateFormat={mwlTimeFormat}
                                        className="form-control-custom"
                                        onChange={(date) => handleMwlDateChange(date)}
                                        selected={studyDate}
                                        dateFormat="MM/dd/yyyy hh:mm aa"
                                        timeIntervals={15}
                                        showMonthDropdown
                                        showYearDropdown
                                        minTime={new Date().setHours(6, 0, 0, 0)}
                                        maxTime={new Date().setHours(21, 0, 0, 0)}
                                        dropdownMode="select"
                                        customInput={
                                            <NumberFormat format="##/##/####" mask="_" />
                                        }
                                    />
                                </div>
                            </Popover.Content>
                            <div className="form-group col-12">
                                {disableBtn ?
                                    <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title="Select a modality">
                                        <button className="btn btn-light" disabled>Add to MWL</button>
                                    </span>
                                    :
                                    <button className="btn btn-submit" onClick={addToWorklist}>Add to MWL</button>
                                }

                                <button className="btn btn-outline-default margin-left-15" onClick={() => { document.body.click() }}>Cancel</button>
                            </div>
                        </Popover>
                    }
                >
                    <button type="button" id="addToMWL" className="unstyleBtn ml-3" data-container="body" data-toggle="popover" data-placement="top">
                        <i className="fas fa-share-alt fa-125x color-pink text-center" id="addToMWL"></i>
                    </button>

                </OverlayTrigger>

            );
        }

        function hxFormatter(cell, row) {
            return (
                <i id="printHx" className="fas fa-file-medical fa-125x color-pink text-center"></i>
            );
        }

        const { ToggleList } = ColumnToggle;
        const columns = [{
            dataField: 'patientModel.mrn',
            text: 'Patient ID'
        }, {
            dataField: 'patientModel.lName',
            text: 'Last Name'
        }, {
            dataField: 'patientModel.fName',
            text: 'First Name'
        }, {
            dataField: 'patientModel.dob',
            text: 'DOB',
            formatter: cell => Moment(new Date(cell)).format('L')
        }, {
            dataField: 'facilityModel.facilityName',
            text: 'Facility'
        }, {
            dataField: 'patientModel.address1',
            text: 'Address'
        }, {
            dataField: 'providerModel.fName',
            text: 'Referring MD',
            formatter: fullNameFormatter
        }, {
            dataField: 'insuranceModel.insCompName',
                text: 'Insurance'
        }, {
            dataField: 'patientModel.densityStatus',
            text: 'Dense',
            formatter: checkboxFormatter
        }, {
            dataField: 'patientModel.riskStatus',
            text: 'High Risk',
            formatter: checkboxFormatter
        }, {
            dataField: 'dum1',
            isDummyField: true,
            text: 'Open Chart',
            formatter: folderFormatter
        }, {
            isDummyField: true,
            dataField: 'd3',
            text: 'Print Hx',
            formatter: hxFormatter
        }, {
            dataField: 'dum2',
            isDummyField: true,
            text: 'Add to MWL',
            formatter: mwlFormatter,
            formatExtraData: {
                st: this.state
            }
        }];

        const columnsMobile = [{
            dataField: 'patientModel.lName',
            text: 'Last Name'
        }, {
            dataField: 'patientModel.fName',
            text: 'First Name'
        }];

        const sizePerPageOptionRenderer = ({
            text,
            page,
            onSizePerPageChange
        }) => (
                <li
                    key={text}
                    role="presentation"
                    className="form-control-custom hover"
                    tabIndex="-1"
                    data-page={page}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        onSizePerPageChange(page);
                    }}
                    style={{ color: 'pink' }}
                >
                    <span className="a hover"
                        tabIndex="-1"
                        role="menuitem"
                        data-page={page}
                        onMouseDown={(e) => {
                            e.preventDefault();
                            onSizePerPageChange(page);
                        }}
                        style={{ color: 'rgba(243, 0, 121, 1)' }}
                    >
                        {text}
                    </span>
                </li>
            );

        const pagination = {
            sizePerPage: 10,
            sizePerPageList: [{ text: "5", value: 5 }, { text: "10", value: 10 }, { text: "50", value: 50 }, { text: "100", value: 100 }], // A numeric array is also available. the purpose of above example is custom the text
            sizePerPageOptionRenderer
        };

        const paginationMobile = {
            sizePerPage: 10,
            pageStartIndex: 1,
            alwaysShowAllBtns: true,
            showTotal: false
        };

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            hideSelectColumn: true,
            
            classes: 'highlight-row',
            selected: [this.state.selectedPatient?.patientModel?.patientID]
        };


        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                if (e.target.tagName === 'I' && e.target.id === 'openChart'/*e.target.tagName === 'TD' || e.target.tagName === 'TR'*/) {

                    getPatientInformationByIDNew(row.patientModel.patientID).then(data => {
                        this.setState({
                            selectedPatient: data.patientInformation
                        });

                        this.props.history.push({
                            pathname: '/patient-dashboard',
                            state: { selectedObject: data.patientInformation, examInfo: data.examInfo, filteredCategoryList: row.categoryList, patientEthnicity: data.patientEthnicity }
                        });
                    }); 
                }
                else if (e.target.tagName === 'I' && e.target.id === 'printHx') {

                    handlePrintHx(row.patientModel.patientID);

                }
                else if (e.target.tagName === 'BUTTON' || e.target.tagName === 'I') {
                    getPatientInformationByIDNew(row.patientModel.patientID).then(data => {

                        this.setState({
                            selectedPatient: data.patientInformation
                        });
                    }); 
                }
            },
            onDoubleClick: (e, row, rowIndex) => {

                getPatientInformationByIDNew(row.patientModel.patientID).then(data => {
                    this.setState({
                        selectedPatient: data.patientInformation
                    });

                    this.props.history.push({
                        pathname: '/patient-dashboard',
                        state: { selectedObject: data.patientInformation, examInfo: data.examInfo, filteredCategoryList: row.categoryList, patientEthnicity: data.patientEthnicity }
                    });
                }); 
            }
        };

        const expandRow = {
            renderer: row => (
                <div>
                    <div><strong>Name: </strong>{row.patientModel.fName} + {row.patientModel.lName}</div>
                    <div><strong>DOB: </strong>{row.patientModel.dob}</div>
                    <div><strong>MRN #: </strong>{row.patientModel.mrn}</div>
                    <div><strong>Address: </strong>{row.patientModel.address1}</div>
                    <div><strong>Referring MD: </strong>{row.patientModel.primaryCarePhysician}</div>
                </div>
            ),
            showExpandColumn: true
        };

        return (
            <div>
                <div className="row">
                    <div className="col-lg-12" >
                        <div className="panel-hdr">
                            <h2>
                                Patients
                            </h2>

                            <button type="button" className="btn btn-submit margin-0-10" onClick={() => this.setState({ showAddPatient: true })}>
                                New Patient
                            </button>
                            <button type="button" className="pinkTextA btn btn-outline-default margin-0-10" onClick={this.handleBulkPrint}>
                                Print List
                            </button>
                            <button type="button" className="pinkTextA btn btn-outline-default margin-0-10" onClick={this.handlePatientExport}>
                                Export List
                            </button>

                            
                        </div>

                    
                        <div className="m-4">
                            <PatientSearchForm updatePatients={this.updatePatients} updatePatientsLoading={this.updatePatientsLoading} />
                            {!this.state.loading && !this.state.noPatients &&
                                <div className="float-right color-pink hover" onClick={this.toggleCustomizeColumns}>
                                    Customize Columns &nbsp;
                                    {this.state.showCustomColumns ?
                                        <i className="fas fa-angle-up"></i> : <i className="fas fa-angle-down"></i>
                                    }
                                </div>
                            }
                        </div>

                        <Fragment>
                            {this.state.initialSearch ?
                                <div className="">
                                    {this.state.loading ?
                                        <span className="frame-heading"><ReactLoading className='tableLoading' type={"spokes"} color={'#F30079'} height={30} width={30} /> Loading ...</span>
                                        :
                                        <div>
                                            {this.state.noPatients ?
                                                <Empty
                                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                    description={
                                                        <span>
                                                            No Patients Found
                                                        </span>
                                                    }
                                                >
                                                </Empty>
                                                :
                                                <Fragment>
                                                    <div className="fullTable">
                                                        <ToolkitProvider
                                                            keyField='patientModel.patientID'
                                                            data={this.state.patients}
                                                            columns={columns}
                                                            columnToggle
                                                        >
                                                            {
                                                                props => (
                                                                    <div>
                                                                        {this.state.showCustomColumns &&
                                                                            <ToggleList {...props.columnToggleProps} />
                                                                        }
                                                                        <hr />
                                                                        <BootstrapTable
                                                                            pagination={paginationFactory(pagination)}
                                                                            {...props.baseProps}
                                                                            rowEvents={rowEvents}
                                                                            selectRow={selectRow}
                                                                            hover condensed />
                                                                    </div>
                                                                )
                                                            }
                                                        </ToolkitProvider>

                                                    </div>
                                                    <div className="mobileTable">
                                                        <BootstrapTable keyField='patientModel.patientID'
                                                            data={this.state.patients}
                                                            columns={columnsMobile}
                                                            pagination={paginationFactory(paginationMobile)}
                                                            rowEvents={rowEvents}
                                                            expandRow={expandRow} hover condensed />
                                                    </div>
                                                </Fragment>
                                            }
                                        </div>
                                    }

                                </div>
                                :
                                <h2 className="ml-3 mt-3">Begin by searching for a patient</h2>
                            }
                        </Fragment>
                    </div>
                </div>
                {this.state.showAddPatient && <AddPatientPane showPane={this.state.showAddPatient} hidePane={() => this.setState({ showAddPatient: false })} />}
            </div>
        );
    }
}

export default withRouter(PatientsHome);