import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { Modal } from 'react-bootstrap';

import { toast } from '@rickylandino/react-messages';

import { insertIssuer } from '../../common/services/MaintenanceService';

export default function IssuerOfPatientModal(props) {
    const { register, handleSubmit, setValue, getValues,
        formState: { errors }, control } = useForm();

    const [modalInfo, setModalInfo] = useState({
        showModal: false,
        iopItem: {
            issuerName: '',
            inactiveYN: false
        }
    });

    useEffect(() => {
        setModalInfo({
            ...modalInfo,
            showModal: true
        });
    }, []);

    function closeModal() {

        setModalInfo({
            showModal: false,
            iopItem: {
                issuerName: '',
                inactiveYN: false
            }
        });
    }

    function handleUpdate() {
        var item = modalInfo.iopItem;

        item.inactiveYN = getValues().inactiveYN ? 1 : 0;
        item.issuerName = getValues().issuerName;

        insertIssuer(item).then(data => {
            toast.success("Issuer of Patient Added");

            if (props.from === 'manageFacility') {
                item.issuerofPatientID = data;
                setTimeout(() => {
                    props.hideModal(item);
                }, 1000);
            }

            closeModal();
        })
    }

    return (
        <Modal size='lg' show={modalInfo.showModal} backdrop='static' onHide={closeModal}>
            <Modal.Header>
                <h1>Issuer of Patient</h1>
            </Modal.Header>
            <Modal.Body>
                <div className="panel-content">
                    <div className="row">
                        <div className="form-group col-12">
                            <label className="form-label">Issuer Name</label>
                            <input className="form-control-custom" {...register("issuerName")} />
                        </div>

                        <div className="form-group col-12">
                            <label className="form-label">Set Inactive</label>
                            <div className="form-control-custom no-border">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" {...register("inactiveYN")} />
                                    <label className="custom-control-label"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="row">
                    <div className="form-group col-12 padding-25-10">
                        <button className="btn btn-submit" onClick={handleUpdate}>Save</button>
                        <button className="btn btn-outline-default margin-left-15" id="closeModal" onClick={closeModal}>Cancel</button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}