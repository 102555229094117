import React, { Component, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import Globals from '../../config/globals';

import { Result, Spin, Button, Typography, message, Select } from 'antd';
import { InboxOutlined, LoadingOutlined, CheckCircleTwoTone, CloseCircleTwoTone, InfoCircleTwoTone } from '@ant-design/icons';


import { useDropzone } from 'react-dropzone';
import { importPatients, exportErrorList } from '../../common/services/ApiService';

import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { userAssociatedFacilities } from '../../common/services/ApiService';
import Moment from 'moment';
import { Fragment } from 'react';

function MyDropzone(props) {
    const maxSize = 1048576;

    const onDrop = useCallback(acceptedFiles => {
        const reader = new FileReader()
        let file = acceptedFiles[0];

        reader.onload = async function () {
            props.setFile(file);
            //props.addFile(file);

        }
        reader.readAsArrayBuffer(file);
    }, [])

    const { isDragActive, getRootProps, getInputProps, isDragReject, rejectedFiles } = useDropzone({
        onDrop,
        minSize: 0,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/xlsx, application/xls, .csv, application/vnd.ms-excel, text/csv'
    });

    const isFileTooLarge = rejectedFiles?.length > 0 && rejectedFiles[0].size > maxSize;

    return (
        <div className="ant-upload ant-upload-drag pt-4 mb-4">
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                {!isDragActive && 'Click here or drop an excel sheet here'}
                {isDragActive && !isDragReject && "Drop the file!"}
                {isDragReject && "File type not accepted, sorry!"}
                {isFileTooLarge && (
                    <div className="text-danger mt-2">
                        File is too large.
                    </div>
                )}
            </div>
        </div>
    );

}

class ImportPatients extends Component {


    constructor(props) {
        super(props);

        this.state = {
            file: {},
            showPane: props.showPane,
            loading: false,
            userAssociatedFacilities: [],
            loaded: false,
            formFields: {
                facilityID: ''
            },
            failedImports: [],
            importResponse: {},
            importFinished: false
        }

        this.isDirty = false;
        this.alive = false;
    }

    componentDidMount() {
        this.alive = true;

        combineLatest(
            userAssociatedFacilities
        ).pipe(takeWhile(() => this.alive)).subscribe(([af]) => {
            if (af?.length > 0) {

                let defaultFacility = af.find(f => f.defaultFacility === 1)?.facilityModel?.facilityID || af[0].facilityModel?.facilityID;
                let facilityID = this.state.formFields.facilityID === '' ? defaultFacility : this.state.formFields.facilityID;
                
                this.setState({
                    userAssociatedFacilities: af,
                    loaded: true,
                    formFields: {
                        facilityID
                    }
                });
            }
        });
    }

    componentWillUnmount() {
        this.alive = false;
    }

    hidePane = () => {
        this.setState({
            showPane: false
        });

        this.props.hidePane();
    }

    handleSubmit = () => {
        if (this.state.file.name) {
            this.setState({
                loading: true
            });

            importPatients(this.state.file, this.state.formFields.facilityID).then(data => {
                this.setState({
                    file: {},
                    loading: false,
                    importResponse: data,
                    failedImports: data?.failedImports ? data.failedImports : [],
                    importFinished: true
                });

                if (data.failedImports?.filter(fi => fi.reason === 'Incorrect Format').length > 0) {
                    message.error("Import failed");
                } else {
                    message.success("Patient import has finished");
                }
                
            });
        } else {
            message.error("Please select a file first");
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    handleFacilityChange = (selectedFacilityID) => {
        this.setState({ formFields: { ...this.state.formFields, facilityID: selectedFacilityID } });
    }

    goToPatients = () => {
        this.hidePane();

        Globals.patients = [];
        this.props.history.replace({
            pathname: '/patients',
            state: { patients: [] }
        });
    }

    handleErrorExport = () => {
        const key = "loading";
        message.loading({ content: 'Exporting Errors...', key, duration: 0 });
        let fileName = "error-results_" + Moment().format('YYYYMMDDHm') + '.xlsx';

        exportErrorList(this.state.failedImports).then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = fileName;//"File.xlsx";
            //document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);
        }).catch(error => {
            console.log("Error");
        });
    }

    render() {

        const antIcon = <LoadingOutlined spin />;
        const { Paragraph, Text } = Typography;

        const duplicateImports = this.state.failedImports?.filter(item => item.reason === 'Duplicate MRN');
        const duplicatePatientIDs = this.state.failedImports?.filter(item => item.reason === 'Duplicate Patient ID');
        const invalidProviders = this.state.failedImports?.filter(item => item.reason === 'Invalid Provider');
        const invalidInsurance = this.state.failedImports?.filter(item => item.reason === 'Invalid Insurance');
        const incorrectFormat = this.state.failedImports?.filter(item => item.reason === 'Incorrect Format');
        const errorImports = this.state.failedImports?.filter(item => item.reason === 'Error');

        const theme = window.sessionStorage.getItem("theme");

        const { Option } = Select;

        return (
            <Fragment>

            {/*<SlidingPane*/}
            {/*    overlayClassName='pinkCard'*/}
            {/*    className={theme === 'dark' ? "dark-theme" : 'light-theme'}*/}
            {/*    isOpen={this.state.showPane || false}*/}
            {/*    title='Import Patients'*/}
            {/*    onRequestClose={this.hidePane}*/}
            {/*    shouldCloseOnEsc={false}*/}
            {/*>*/}

                <div className={this.state.failedImports?.length > 0 ? "slide-pane-body" : "slide-pane-body h-100"}>
                    <div className="row">
                        <div className="form-group col-lg-4 mb-3">
                            <label className="form-label">Selected Facility</label>
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={this.handleFacilityChange}
                                className="form-control-custom w-100"
                                bordered={false}
                                value={this.state.formFields.facilityID || ''}
                                showSearch
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            >
                                {this.state.userAssociatedFacilities.map((fac, idx) => <Option key={idx} key={fac.facilityModel.facilityID} value={fac.facilityModel.facilityID}>{fac.facilityModel.facilityNumber && fac.facilityModel.facilityNumber + " - "}{fac.facilityModel.facilityName}</Option>)}
                            </Select>
                        </div>
                    </div>
                    <MyDropzone setFile={(file) => this.setState({ file })} />
                    {this.state.file?.name && <pre className="mb-4">{this.state.file.name}</pre>}
                    <button className="btn btn-submit" onClick={this.handleSubmit}>{this.state.loading ? <Spin indicator={antIcon} className="text-white" /> : <span>Import Patients</span>} </button>

                    {this.state.importFinished &&
                        <Result
                            status="success"
                            title="Import Completed"
                            subTitle="Please check below for any errors."
                            extra={<button onClick={this.goToPatients} className="pinkTextA btn btn-outline-default" key="console">
                                View Patients
                                </button>}
                        >
                            <div className="desc">
                                <Paragraph className="text-center">
                                    <Text
                                        strong
                                        style={{
                                            fontSize: 16
                                        }}
                                >
                                    <CheckCircleTwoTone twoToneColor="#52c41a" /> &nbsp; {this.state.importResponse?.totalSuccess} of {this.state.importResponse.totalCount} have successfully been imported.
                                    <br />
                                    <CloseCircleTwoTone twoToneColor="#ff4d4f" /> &nbsp; {this.state.importResponse?.totalFailed} of {this.state.importResponse.totalCount} have failed. Please see below for more details.
                            </Text>
                                </Paragraph>
                            <div className="row">
                                {incorrectFormat.length > 0 &&
                                    <div className="col col-lg-6">
                                        <Paragraph>
                                            <Text
                                                strong
                                                style={{
                                                    fontSize: 16,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                The upload sheet has incorrectly formatted data. Please contact your system admin.
                                        </Text>
                                        </Paragraph>
                                    </div>
                                }

                                {duplicateImports.length > 0 &&
                                    <div className="col col-lg-6">
                                        <Paragraph>
                                            <Text
                                                strong
                                                style={{
                                                    fontSize: 16,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                The following have failed because the MRN associated to the patient already exists:
                                        </Text>
                                        </Paragraph>

                                        <div className="display-third">
                                            {duplicateImports.map((item, idx) => (

                                                <Paragraph key={idx}>
                                                    <InfoCircleTwoTone twoToneColor="#ff4d4f" />&nbsp;
                                                    <b>{item.patient.firstName} {item.patient.lastName}</b> - {item.patient.mrn} -
                                                    Row Number: <b>{item.rowNumber}</b>
                                                </Paragraph>
                                            ))
                                            }
                                        </div>
                                    </div>
                                }

                                {duplicatePatientIDs.length > 0 && 
                                    <div className="col col-lg-6">
                                        <Paragraph>
                                            <Text
                                                strong
                                                style={{
                                                    fontSize: 16,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                The following have failed because the Patient ID associated to the patient already exists:
                                        </Text>
                                        </Paragraph>

                                        <div className="display-third">
                                        {duplicatePatientIDs.map((item, idx) => (

                                                <Paragraph key={idx}>
                                                    <InfoCircleTwoTone twoToneColor="#ff4d4f" />&nbsp;
                                                    <b>{item.patient.firstName} {item.patient.lastName}</b> - {item.patient.mrn} -
                                                    Row Number: <b>{item.rowNumber}</b>
                                                </Paragraph>
                                            ))
                                            }
                                        </div>
                                    </div>
                                }

                                {invalidProviders.length > 0 &&
                                    <div className="col col-lg-6">
                                        <Paragraph>
                                            <Text
                                                strong
                                                style={{
                                                    fontSize: 16,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                The following have failed because the Provider associated to the patient does not exist:
                                        </Text>
                                        </Paragraph>

                                        <div className="display-third">
                                        {invalidProviders.map((item, idx) => (

                                                <Paragraph key={idx}>
                                                    <InfoCircleTwoTone twoToneColor="#ff4d4f" />&nbsp;
                                                    <b>{item.patient.firstName} {item.patient.lastName}</b> - {item.patient.mrn} -
                                                    Row Number: <b>{item.rowNumber}</b>
                                                </Paragraph>
                                            ))
                                            }
                                        </div>
                                    </div>
                                }

                                {invalidInsurance.length > 0 &&
                                    <div className="col col-lg-6">
                                        <Paragraph>
                                            <Text
                                                strong
                                                style={{
                                                    fontSize: 16,
                                                    textAlign: 'center'
                                                }}
                                            >
                                            The following have failed because the Insurance Company associated to the patient does not exist:
                                        </Text>
                                        </Paragraph>

                                        <div className="display-third">
                                        {invalidInsurance.map((item, idx) => (

                                                <Paragraph key={idx}>
                                                    <InfoCircleTwoTone twoToneColor="#ff4d4f" />&nbsp;
                                                    <b>{item.patient.firstName} {item.patient.lastName}</b> - {item.patient.mrn} -
                                                    Row Number: <b>{item.rowNumber}</b>
                                                </Paragraph>
                                            ))
                                            }
                                        </div>
                                    </div>
                                }

                                {errorImports.length > 0 &&
                                    <div className="col col-lg-6">
                                        <Paragraph>
                                            <Text
                                                strong
                                                style={{
                                                    fontSize: 16,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                The following have failed due to an unknown cause. Please check that the column headers have no leading or trailing spaces. These may need to be input manually:
                                        </Text>
                                        </Paragraph>

                                        <div className="display-third">
                                            {errorImports.map((item, idx) => (

                                                <Paragraph key={idx}>
                                                    <CloseCircleTwoTone twoToneColor="#ff4d4f" /> &nbsp; 
                                                    <b>{item.patient.firstName} {item.patient.lastName}</b> - {item.patient.mrn} -
                                                    Row Number: <b>{item.rowNumber}</b>
                                                </Paragraph>
                                            ))
                                            }
                                        </div>
                                    </div>
                                }
                                </div>
                            </div>
                        </Result>
                    }
                </div>
                {this.state.failedImports?.length > 0 &&
                    <div className="modal-footer">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-submit" onClick={this.handleErrorExport}>Export Errors</button>
                        </div>
                    </div>
                }
        {/*</SlidingPane>*/ }
        </Fragment>
        );
    }
}

export default withRouter(ImportPatients);