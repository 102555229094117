import React, { Component, Fragment } from "react";
import { Container, Draggable } from "react-smooth-dnd";
import {
    reportComponents, reportDefinitions, reportComponentsSplit, reportComponentTypes,
    insertReportDefinition, updateReportDefinition, getReportDefinitionPieces, reportDefinitionTypes,
    deleteReportDefinition
} from "../../../common/services/ReportService";
import { takeWhile } from "rxjs/operators";

import { Editor } from '@tinymce/tinymce-react';

import Globals from '../../../config/globals';

import '../../../css/editor.css';
import Floater from 'react-floater';

import { Markup } from 'interweave';
import { combineLatest } from "rxjs";

import { SmartMessageBox } from "../../../common/utils/functions";
import { userAssociatedFacilities } from "../../../common/services/ApiService";

import { toast } from '@rickylandino/react-messages';
import { Select } from 'antd';


class OrderComponents extends Component {
    constructor(props) {
        super(props);

        this.alive = false;

        this.state = {
            editorContent: '',
            componentList: [],
            fullComponentList: [],
            filteredComponentList: [],
            scene: {
            },
            templateID: '',
            definitionList: [],
            formFields: {
                definitionMaster: {
                    definitionMasterID: ''
                },
                definitionDetails: []
            },
            filterFields: {},
            previousAvailItems: {},
            previousSelectItems: {},
            reportComponentsSplit: {},
            componentTypesList: [],
            addDefinition: false,
            definitionTypesList: [],
            selectedDefinitionType: {},
            fullDefinitionList: [],
            associatedFacilities: [],
            showClonedBanner: false,
            newDefinitionName: '',
            showDelete: false,
            selectedComponentType: {}
        };
    }

    componentDidMount() {
        this.alive = true;

        getReportDefinitionPieces();

        combineLatest(
            reportComponents,
            reportDefinitions,
            reportComponentsSplit,
            reportComponentTypes,
            reportDefinitionTypes
        ).pipe(takeWhile(() => this.alive)).subscribe(([rc, rd, rcs, rct, rdt]) => {
            if (rd?.length > 0 && rc?.length > 0 && rct?.length > 0 && rdt?.length > 0) {
                let formFields = this.state.formFields;
                let definitionDetails = [];
                if (formFields.definitionMaster?.definitionMasterID) {
                    let def = rd.find(d => d.definitionMaster.definitionMasterID === formFields.definitionMaster.definitionMasterID);
                    if (def) definitionDetails = def.definitionDetails;
                }

                this.setState({
                    filteredComponentList: !this.state.selectedComponentType?.reportComponentTypesID ? rc.filter(c => c.reportComponentTypesID === rct[0].reportComponentTypesID) : rc.filter(c => c.reportComponentTypesID === this.state.selectedComponentType.reportComponentTypesID),
                    fullComponentList: rc,
                    definitionList: !this.state.selectedDefinitionType.reportDefinitionTypesID ? rd.filter(c => c.definitionMaster.reportDefinitionTypesID === rdt[0].reportDefinitionTypesID) : rd.filter(c => c.definitionMaster.reportDefinitionTypesID === this.state.selectedDefinitionType.reportDefinitionTypesID),
                    fullDefinitionList: rd,
                    reportComponentsSplit: rcs,
                    componentTypesList: rct,
                    selectedComponentType: !this.state.selectedComponentType.reportComponentTypesID ? rct[0] : this.state.selectedComponentType,
                    definitionTypesList: rdt,
                    selectedDefinitionType: !this.state.selectedDefinitionType.reportDefinitionTypesID ? rdt[0] : this.state.selectedDefinitionType
                }, () => this.handleSetSelectedComponents(definitionDetails, true));
            }
        });

        userAssociatedFacilities.pipe(takeWhile(() => this.alive)).subscribe((data) => {
            if (data?.length > 0) {
                this.setState({
                    associatedFacilities: data
                });
            }
        });
    }

    componentWillUnmount() {
        this.alive = false;
    }

    handleSetSelectedComponents = (value, changeSelected) => {
        let editorContent = '';

        let componentList = [...this.state.filteredComponentList];
        let fullComponentList = [...this.state.fullComponentList];

        let selectedComponentsList = [];
        let selectedItems = {};

        if (changeSelected) {
            for (var i = 0; i < value.length; i++) {
                var idx = fullComponentList.findIndex(cl => cl.componentID === value[i].component.componentID);
                editorContent += fullComponentList[idx]?.componentHtml;
                if (idx >= 0) fullComponentList[idx].isLocked = value[i].definitionDetail.isLocked === 1 ? true : false;

                selectedComponentsList.push(fullComponentList[idx]);
            }

            let selectedChildren = selectedComponentsList.map((rc, idx) => ({
                id: rc.componentID,
                content: rc.componentHtml,
                type: "draggable",
                locked: rc.isLocked,
                props: {
                    className: "d-card",
                    style: {
                        backgroundColor: 'rgba(255,192,203,.4)'
                    }
                },
                data: rc.componentName,
                componentObject: rc
            }));

            selectedItems = {
                id: '2',
                type: 'container',
                name: 'Selected Items',
                props: {
                    orientation: "vertical",
                    className: "card-container"
                },
                children: selectedChildren
            }
        } else {
            selectedItems = this.state.scene.children.find(s => s.name === 'Selected Items');
            editorContent = this.state.editorContent;
        }

        componentList = componentList.filter(c => !(selectedItems.children.find(si => si.id === c.componentID)) && (!c.facilityID || c.facilityID === this.state.formFields.definitionMaster.facilityID));
        
        let availableChildren = componentList.map((rc, idx) => ({
            id: rc.componentID,
            content: rc.componentHtml,
            type: "draggable",
            locked: false,
            props: {
                className: "d-card",
                style: {
                    backgroundColor: 'rgba(255,192,203,.4)'
                }
            },
            data: rc.componentName,
            componentObject: rc
        }));

        let availableItems = {
            id: '1',
            type: 'container',
            name: 'Available Items',
            props: {
                orientation: "vertical",
                className: "card-container"
            },
            children: availableChildren
        }

        let scene = {
            type: "container",
            props: {
                orientation: "horizontal"
            },
            children: [availableItems, selectedItems]
        }

        this.setState({
            componentList,
            scene,
            editorContent,
            templateID: Math.random()
        });
    }

    getCardPayload = (columnId, index) => {
        return this.state.scene.children.filter(p => p.id === columnId)[0].children[
            index
        ];
    }

    onCardDrop = (columnId, dropResult) => {
        //if item is locked, don't allow drag and drop feature
        const scene = Object.assign({}, this.state.scene);
        const column = scene.children.filter(p => p.id === columnId)[0];
        const columnIndex = scene.children.indexOf(column);

        var stickyColumn = {};

        var reorderWithinSelected = false;

        if (!dropResult.payload.locked) {
            if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
                if (dropResult.removedIndex !== null) {
                    stickyColumn = column;
                }

                const itemAboutToMove = column.children[dropResult.addedIndex];
                const newColumn = Object.assign({}, column);
                //if item is being dropped into a locked item, don't move

                if (column.name === "Selected Items") {
                    reorderWithinSelected = stickyColumn?.children?.length === this.state.previousSelectItems.children.length ? true : false
                }

                newColumn.children = applyDrag(newColumn.children, dropResult);

                scene.children.splice(columnIndex, 1, newColumn);

                let selectedItems = scene.children.find(s => s.name === 'Selected Items');

                let editorContent = '';

                for (var i = 0; i < selectedItems.children.length; i++) {
                    editorContent += selectedItems.children[i].content;
                }

                this.setState({
                    editorContent
                });

                //if ((itemAboutToMove && (!itemAboutToMove?.locked || (!reorderWithinSelected && dropResult.addedIndex < column.children.length)) && ((!reorderWithinSelected && dropResult.addedIndex && dropResult.addedIndex < column.children.length)) || (reorderWithinSelected && dropResult.addedIndex && dropResult.addedIndex + 1 < column.children.length)) ||
                //    (!itemAboutToMove && ((dropResult.addedIndex === column.children.length && !column.children[column.children.length - 1]?.locked) || dropResult.addedIndex !== column.children.length))
                //    //(!reorderWithinSelected && dropResult.addedIndex && dropResult.addedIndex+1 < column.children.length) ||
                //    //(reorderWithinSelected && dropResult.addedIndex && dropResult.addedIndex+1 < column.children.length)
                //) {

                //    newColumn.children = applyDrag(newColumn.children, dropResult);

                //    scene.children.splice(columnIndex, 1, newColumn);

                //    let selectedItems = scene.children.find(s => s.name === 'Selected Items');

                //    let editorContent = '';

                //    for (var i = 0; i < selectedItems.children.length; i++) {
                //        editorContent += selectedItems.children[i].content;
                //    }

                //    this.setState({
                //        editorContent
                //    });

                //} else {
                //    scene.children[0] = this.state.previousAvailItems;
                //    scene.children[1] = this.state.previousSelectItems;
                //}

                this.setState({
                    scene,
                    templateID: Math.random()
                });
            }
        }
    }

    handleDefinitionSelection = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        let formFields = {};
        let templateID = '';

        if (value === '') {
            this.addDefinition();

        } else {
            formFields = this.state.definitionList.find(d => d.definitionMaster.definitionMasterID === value.toString());
            formFields.reportComponentTypesID = this.state.selectedComponentType?.reportComponentTypesID;
            formFields.associateFacility = formFields.definitionMaster.facilityID ? true : false;

            templateID = formFields.definitionMaster.definitionMasterID;
            

            this.setState({ formFields, templateID, showClonedBanner: false, showDelete: true }, () => this.handleSetSelectedComponents(formFields.definitionDetails, true));
        }


    }

    handleFacilityChange = (selectedFacilityID) => {
        let formFields = this.state.formFields;

        formFields.definitionMaster.facilityID = selectedFacilityID;
        this.handleSetSelectedComponents(formFields.definitionDetails, true);
        this.setState({ formFields }, () => {
            this.isDirty = true;
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let formFields = this.state.formFields;

        if (name === 'definitionName') {
            formFields.definitionMaster.definitionName = value;

            this.setState({ formFields }, () => {
                this.isDirty = true;
            });

        } else if (name === 'reportDefinitionTypesID') {
            formFields.definitionMaster.reportDefinitionTypesID = value;

            this.setState({ formFields }, () => {
                this.isDirty = true;
            });

        } else if (name === 'associateFacility') {
            formFields.definitionMaster.facilityID = value ? formFields.facilityID : null;
            formFields.associateFacility = value;

            this.setState({ formFields }, () => {
                this.isDirty = true;
            });

        } else if (name === 'facilityID') {
            formFields.definitionMaster.facilityID = value;
            this.handleSetSelectedComponents(formFields.definitionDetails, true);
            this.setState({ formFields }, () => {
                this.isDirty = true;
            });

        } else {
            this.setState({ formFields: { ...this.state.formFields, [name]: value } }, () => {
                this.isDirty = true;
            });
        }


    }

    handleDefinitionTypeChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        let selectedDefinitionType = this.state.definitionTypesList.find(dt => dt.reportDefinitionTypesID === value);
        let definitionList = this.state.fullDefinitionList.filter(d => d.definitionMaster.reportDefinitionTypesID === value);

        this.setState({
            selectedDefinitionType,
            definitionList,
            formFields: {
                definitionMaster: {
                    definitionMasterID: ''
                },
                definitionDetails: []
            },
            selectedDefinitionType,
            showClonedBanner: false
        }, () => {
            this.isDirty = true;
            Globals.isDirtyPage = true;
        })
    }

    handleToggleLock = (idx) => {
        let selectedItems = this.state.scene.children.find(c => c.name === 'Selected Items');

        let componentList = selectedItems.children.map(item => (item.componentObject));

        let isLockedNow = this.state.scene.children[1].children[idx].locked;

        let scene = this.state.scene;

        componentList[idx].toBeLocked = !isLockedNow;

        scene.children[1].children[idx].locked = !isLockedNow;

        this.setState({
            scene,
            componentList,
        });
    }

    handleHighlight = (idx) => {
        let editorContent = '';
        let selectedItems = this.state.scene.children.find(s => s.name === 'Selected Items');

        for (var i = 0; i < selectedItems.children.length; i++) {
            editorContent += i === idx ? "<div id='content_500' style='background-color: #fbeeb8;'>" + selectedItems.children[i].content + "</div>" : selectedItems.children[i].content;
        }

        this.setState({
            editorContent,
            templateID: Math.random()
        });
    }

    handleDefinitionUpdate = (from) => {

        let selectedItems = this.state.scene.children.find(c => c.name === 'Selected Items');

        let componentList = selectedItems.children.map(item => (item.componentObject));

        if (!this.state.formFields.definitionMaster?.definitionName ||
            !this.state.formFields.definitionMaster?.reportDefinitionTypesID ||
            componentList.length === 0) {

            toast.warning("Please make sure name and content are filled out.");

        } else {

            let postdata = {
                reportDefinitionMasterModel: this.state.formFields.definitionMaster,
                reportDefinitionComponentsList: componentList
            }

            if (this.state.formFields.definitionMaster?.definitionMasterID && this.state.formFields.definitionMaster.definitionMasterID !== "00000000-0000-0000-0000-000000000000") {

                updateReportDefinition(postdata).then(data => {

                    toast.success("Definition has been updated.");

                    let selectedDefinitionType = this.state.definitionTypesList.find(dt => dt.reportDefinitionTypesID === this.state.formFields.definitionMaster.reportDefinitionTypesID);
                    let definitionList = this.state.fullDefinitionList.filter(d => d.definitionMaster.reportDefinitionTypesID === this.state.formFields.definitionMaster.reportDefinitionTypesID);

                    this.setState({
                        selectedDefinitionType,
                        definitionList,
                        showClonedBanner: false
                    }, () => {
                        getReportDefinitionPieces();
                    });
                });
            } else {
                insertReportDefinition(postdata).then(data => {

                    let showClonedBanner = false;
                    if (typeof from === 'string' && from === 'cloned') {
                        showClonedBanner = true;
                    }

                    let formFields = this.state.formFields;

                    formFields.definitionMaster.definitionMasterID = data;


                    let selectedDefinitionType = this.state.definitionTypesList.find(dt => dt.reportDefinitionTypesID === formFields.definitionMaster.reportDefinitionTypesID);
                    let definitionList = this.state.fullDefinitionList.filter(d => d.definitionMaster.reportDefinitionTypesID === formFields.definitionMaster.reportDefinitionTypesID);

                    this.setState({
                        formFields,
                        selectedDefinitionType,
                        definitionList,
                        addDefinition: false,
                        showDelete: true,
                        showClonedBanner
                    }, () => {
                        getReportDefinitionPieces();
                    });

                    toast.success("Definition has been added");
                });
            }
        }
    }

    handleStartDrag = (columnId) => {
        const scene = Object.assign({}, this.state.scene);
        const column = scene.children.filter(p => p.id === columnId)[0];
        if (parseInt(columnId) === 1) {
            this.setState({
                previousAvailItems: column
            });
        } else if (parseInt(columnId) === 2) {
            this.setState({
                previousSelectItems: column
            });
        }
    }

    addDefinition = () => {
        this.setState({
            editorContent: '',
            formFields: {
                definitionMaster: {},
                definitionDetails: [],
                reportComponentTypesID: this.state.selectedComponentType.reportComponentTypesID
            },
            addDefinition: true,
            showClonedBanner: false,
            showDelete: false
        });

        this.handleSetSelectedComponents([], true);
    }

    cancelAddDefinition = () => {
        this.setState({
            editorContent: '',
            formFields: {
                definitionMaster: {
                    definitionMasterID: ''
                },
                definitionDetails: [],
                reportComponentTypesID: this.state.selectedComponentType.reportComponentTypesID
            },
            addDefinition: false
        });

    }

    filterComponentType = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let filterFields = { ...this.state.filterFields, [name]: value };

        let selectedComponentType = this.state.componentTypesList.find(ct => ct[name] === value);

        let filteredComponentList = [];

        if (filterFields.facilityID && filterFields.reportComponentTypesID) {
            filteredComponentList = this.state.fullComponentList.filter(c => c.reportComponentTypesID === filterFields.reportComponentTypesID && c.facilityID === filterFields.facilityID);
        } else if (filterFields.facilityID) {
            filteredComponentList = this.state.fullComponentList.filter(c => c.facilityID === filterFields.facilityID);
        } else if (filterFields.reportComponentTypesID) {
            filteredComponentList = this.state.fullComponentList.filter(c => c.reportComponentTypesID === filterFields.reportComponentTypesID);
        } else {
            filteredComponentList = this.state.fullComponentList.filter(c => c[name] === value);
        }
        

        let componentList = filteredComponentList.map(c => ({ component: c, definitionDetail: {} }))

        

        this.setState({
            selectedComponentType,
            filteredComponentList,
            componentList,
            filterFields
        }, () => {
            this.isDirty = true;
            Globals.isDirtyPage = true;
            this.handleSetSelectedComponents(componentList, false)
        });

    }

    cloneDefinition = () => {
        let formFields = { ...this.state.formFields };

        formFields.definitionMaster.definitionMasterID = "00000000-0000-0000-0000-000000000000";

        this.setState({
            formFields,

        }, () => {
            this.handleDefinitionUpdate("cloned");
        });
    }

    deleteDefinition = () => {

        SmartMessageBox(
            {
                title: "You are about to delete this definition.",
                content:
                    "Are you sure you would like to proceed?",
                buttons: "[No][Yes]"
            },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {

                    let postdata = this.state.formFields.definitionMaster;

                    deleteReportDefinition(postdata).then(data => {
                        if (data === 1) {

                            toast.success("Definition has been deleted");

                            this.isDirty = false;
                            Globals.isDirtyPage = false;

                            this.setState({
                                formFields: {
                                    definitionMaster: {
                                        definitionMasterID: ''
                                    },
                                    definitionDetails: []
                                },
                                editorContent: '',
                                showDelete: false,
                            }, () => getReportDefinitionPieces());

                            

                        } else {
                            toast.error("Something went wrong.");
                        }
                    });

                    return 0;
                }
                if (ButtonPressed === "No") {
                    window.history.pushState(null, null, null);
                    return 0;
                }
            }
        );

    }

    render() {

        const column = this.state.scene?.children && this.state.scene.children[0];
        const column2 = this.state.scene?.children && this.state.scene.children[1];

        const { Option } = Select;

        return (
            <Fragment>

                <div className="row mt-3">
                    <div className="col-lg-3 col-12 mb-5">
                        <Fragment>
                            <label className="form-label w-100">Filter By Definition Type</label>
                            <select className="form-control-custom" value={this.state.selectedDefinitionType.reportDefinitionTypesID} name="reportDefinitionTypesID" onChange={this.handleDefinitionTypeChange}>
                                {this.state.definitionTypesList.map((item, idx) => <option key={idx} value={item.reportDefinitionTypesID}>{item.definitionTypeName}</option>)}
                            </select>
                        </Fragment>
                    </div>

                    <div className="col">
                        <button className="float-right btn btn-submit margin-0-10" onClick={this.handleDefinitionUpdate}>Save</button>

                        <button className="float-right btn btn-info margin-0-10" onClick={this.cloneDefinition} disabled={this.state.formFields.definitionMaster.definitionMasterID === '' ? true : false}> <i className="fal fa-copy" /> &nbsp; Clone This Definition</button>

                        {!this.state.addDefinition ?
                            <button type="button" className="btn btn-success margin-0-10 float-right" onClick={this.addDefinition}>
                                <i className="far fa-plus-square" /> Add New Definition
                            </button>
                            :
                            <span className="a hover float-right color-pink" onClick={this.cancelAddDefinition}>Cancel New Definition <i className="fa fa-times" /></span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col col-12">
                        {this.state.showClonedBanner &&
                            <div className="alert alert-info" role="alert">
                                Cloned Definition - You are now working with a new copy of the original.
                            </div>
                        }
                    </div>
                    {!this.state.addDefinition &&
                        <div className="col-lg-3 col-12 mb-5">
                            <label className="form-label">Defintions</label>
                            {this.state.definitionList.length > 0 ?
                                <select className="form-control-custom" value={this.state.formFields.definitionMaster.definitionMasterID || ''} name="definitionMasterID" onChange={this.handleDefinitionSelection}>
                                    <option value='' disabled>Select a definition</option>
                                    {this.state.definitionList.map((item, idx) => <option key={idx} value={item?.definitionMaster?.definitionMasterID}>{item?.definitionMaster?.definitionName}</option>)}
                                </select>
                                :
                                <p className="ml-4"><em>No definitions for this type.</em></p>
                            }
                        </div>
                    }
                </div>
                {this.state.formFields.definitionMaster.definitionMasterID !== '' &&
                    <Fragment>
                        <div className="row">
                            <div className="form-group col-lg-8 col-12">
                                <label className="form-label">Definition Name <span className="color-pink">*</span></label>
                                <input type="text" name="definitionName" value={this.state.formFields.definitionMaster.definitionName || ''} onChange={this.handleInputChange} className="form-control-custom" />

                            </div>
                            <div className="col-lg-4 col-12">
                            <label className="form-label">Definition Type <span className="color-pink">*</span></label>
                                <select className="form-control-custom" value={this.state.formFields.definitionMaster.reportDefinitionTypesID || ''} name="reportDefinitionTypesID" onChange={this.handleInputChange}>
                                    <option value='' disabled>Select a Definition Type</option>
                                    {this.state.definitionTypesList.map((item, idx) => <option key={idx} value={item.reportDefinitionTypesID}>{item.definitionTypeName}</option>)}
                                </select>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-2 col-12">
                                <label className="form-label">Facility Specific Definition</label>
                                <div className="form-control-custom no-border">
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" className="custom-control-input" id="" name="associateFacility" checked={this.state.formFields.associateFacility || false} onChange={this.handleInputChange} />
                                        <label className="custom-control-label"></label>
                                    </div>
                                </div>
                            </div>
                            {this.state.formFields.associateFacility &&
                                <div className="col-lg-3 col-12 mb-5">
                            <label className="form-label">Select a facility</label>
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={this.handleFacilityChange}
                                className="form-control-custom w-100"
                                bordered={false}
                                value={this.state.formFields.definitionMaster.facilityID || ''}
                                showSearch
                                filterOption={(input, option) => option?.children?.toLowerCase().includes(input?.toLowerCase())}
                            >
                                {this.state.associatedFacilities.filter(item => item.facilityModel?.inactiveYN !== 1).map((fac, idx) => <Option key={idx} key={fac.facilityModel.facilityID} value={fac.facilityModel.facilityID}>{fac.facilityModel.facilityNumber && fac.facilityModel.facilityNumber + " - "}{fac.facilityModel.facilityName}</Option>)}
                            </Select>
                                    {/*<select className="form-control-custom" value={this.state.formFields.definitionMaster.facilityID || ''} name="facilityID" onChange={this.handleInputChange}>*/}
                                    {/*    <option value='' disabled>Select a facility</option>*/}
                                    {/*    {this.state.associatedFacilities.map((item, idx) => <option key={idx} value={item.facilityModel.facilityID}>{item.facilityModel.facilityNumber && item.facilityModel.facilityNumber + " - "}{item.facilityModel.facilityName}</option>)}*/}
                                    {/*</select>*/}
                                </div>
                            }
                        </div>

                    <div className="row">
                        {this.state.showDelete &&
                            <div className="col-12 mb-4">
                            <label className="form-label">Delete This Definition</label>
                            <div className="ml-3 text-danger fa-15x hover" onClick={this.deleteDefinition}><i className="far fa-trash-alt"></i></div>
                            </div>
                        }
                            <div className="horizontal-divider mt-3 mb-3"></div>
                        </div>

                    <div className="row">
                        <div className="col col-12">
                            <label className="form-label">Filter Components By</label>
                        </div>
                        <div className="col col-lg-4 col-12">
                            <div className="row mb-4">
                                <div className="col col-md-6 col-12">
                                    <label className="form-label">Component Type</label>
                                    <select className="form-control-custom" value={this.state.filterFields.reportComponentTypesID || ''} name="reportComponentTypesID" onChange={this.filterComponentType}>
                                        {this.state.componentTypesList.map((item, idx) => <option key={idx} value={item.reportComponentTypesID}>{item.componentTypeName}</option>)}
                                    </select>
                                </div>

                                <div className="col col-md-6 col-12">
                                <label className="form-label">Facility</label>
                                    <select className="form-control-custom" value={this.state.filterFields.facilityID || ''} name="facilityID" onChange={this.filterComponentType}>
                                    <option value=''>All</option>
                                    {this.state.associatedFacilities.map((item, idx) => <option key={idx} value={item.facilityModel.facilityID}>{item.facilityModel.facilityName}</option>)}
                                </select>
                                </div>

                                
                            </div>

                                <div className="card-scene row">
                                    <Container
                                        orientation="horizontal"
                                    >
                                        {column &&
                                            <div key={column.id} className="col col-6">
                                                <div className={column.props.className}>
                                                    <div className="card-column-header">
                                                        <span className="column-drag-handle">&#x2630;</span>
                                                        {column.name}
                                                    </div>
                                                    <Container
                                                        {...column.props}
                                                        groupName="col"
                                                        onDrop={e => this.onCardDrop(column.id, e)}
                                                        getChildPayload={index =>
                                                            this.getCardPayload(column.id, index)
                                                        }
                                                        onDragStart={e => this.handleStartDrag(column.id, e)}

                                                        dropPlaceholder={{
                                                            animationDuration: 150,
                                                            showOnTop: true,
                                                            className: 'drop-preview'
                                                        }}
                                                        dropPlaceholderAnimationDuration={200}
                                                    >
                                                        {column.children.map((card, cardidx) => {
                                                            return (
                                                                card.locked ?
                                                                    <div key={card.id}>
                                                                        <div {...card.props}>
                                                                            <Floater
                                                                                content={
                                                                                    <div>
                                                                                        <Markup content={card.content} />
                                                                                    </div>
                                                                                }
                                                                                disableHoverToClick
                                                                                event="hover"
                                                                                eventDelay={0}
                                                                                placement="top"
                                                                                className="w-100"
                                                                            >

                                                                                <p>{card.data} {Globals.userInfo.isAdmin && <i onClick={() => this.handleToggleLock(cardidx)} className="hover fas fa-lock float-right"></i>}</p>

                                                                            </Floater>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <Draggable key={card.id}>
                                                                        <div {...card.props}>
                                                                            <Floater
                                                                                content={
                                                                                    <div>
                                                                                        <Markup content={card.content} />
                                                                                    </div>
                                                                                }
                                                                                disableHoverToClick
                                                                                event="hover"
                                                                                eventDelay={0}
                                                                                placement="top"
                                                                            >

                                                                                <p>{card.data} {column.name === 'Selected Items' && Globals.userInfo.isAdmin && <i onClick={() => this.handleToggleLock(cardidx)} className="hover fas fa-lock-open float-right"></i>}</p>

                                                                            </Floater>
                                                                        </div>
                                                                    </Draggable>

                                                            );
                                                        })}
                                                    </Container>
                                                </div>
                                            </div>
                                        }
                                        {column2 &&
                                            <div key={column2.id} className="col col-6">
                                                <div className={column2.props.className}>
                                                    <div className="card-column-header">
                                                        <span className="column-drag-handle">&#x2630;</span>
                                                        {column2.name}
                                                    </div>
                                                    <Container
                                                        {...column2.props}
                                                        groupName="col"
                                                        onDrop={e => this.onCardDrop(column2.id, e)}
                                                        getChildPayload={index =>
                                                            this.getCardPayload(column2.id, index)
                                                        }
                                                        onDragStart={e => this.handleStartDrag(column2.id, e)}

                                                        dropPlaceholder={{
                                                            animationDuration: 150,
                                                            showOnTop: true,
                                                            className: 'drop-preview'
                                                        }}
                                                        dropPlaceholderAnimationDuration={200}
                                                    >
                                                        {column2.children.map((card, cardidx) => {
                                                            return (
                                                                card.locked ?
                                                                    <div key={card.id}>
                                                                        <div {...card.props}>
                                                                            <p>{card.data} {/*Globals.userInfo.isAdmin && <i onClick={() => this.handleToggleLock(cardidx)} className="hover fas fa-lock float-right"></i>*/}</p>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <Draggable key={card.id}>
                                                                        <div {...card.props} onMouseOver={() => this.handleHighlight(cardidx)} onMouseLeave={this.handleHighlight}>
                                                                            <p>{card.data} {/*column2.name === 'Selected Items' && Globals.userInfo.isAdmin && <i onClick={() => this.handleToggleLock(cardidx)} className="hover fas fa-lock-open float-right"></i>*/}</p>
                                                                        </div>
                                                                    </Draggable>

                                                            );
                                                        })}
                                                    </Container>
                                                </div>
                                            </div>
                                        }
                                    </Container>
                                </div>
                            </div>

                            <div className="col col-lg-8 col-12">
                                <Editor
                                    className="readOnly-tiny-editor"
                                    initialValue={this.state.editorContent} //This tricks the component to rerendering with correct content as template selection changes
                                    key={this.state.templateID}
                                    disabled={true}
                                    toolbar=''
                                    init={{
                                        height: 1000,
                                        selector: 'textarea',
                                        image_title: true,
                                        //images_upload_handler: example_image_upload_handler,
                                        image_uploadtab: true,
                                        plugins: '',
                                        branding: false,
                                        toolbar: '',

                                    }}
                                />
                            </div>

                            <div className="horizontal-divider mt-3 mb-3"></div>

                            <div className="col col-12">
                                <button className="btn btn-submit" onClick={this.handleDefinitionUpdate}>Save</button>
                            </div>
                        </div>
                    </Fragment>
                }
            </Fragment>
        );
    }


}

export default OrderComponents;

export const applyDrag = (arr, dragResult) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
    }

    return result;
};