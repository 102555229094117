import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { Select, message } from 'antd';
import NumberFormat from 'react-number-format';

import Moment from 'moment';

import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { userAssociatedFacilities, downloadOpenExamsList } from '../../../common/services/ApiService';

import { downloadPathologyDetails } from '../../../common/services/ApiService';

import Axios from '../../../config/axios';

import { toast } from '@rickylandino/react-messages';

class PathologyReportModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: props.showModal,
            selectedBirads: [],
            selectedDates: {
                startDate: null,
                endDate: new Date()
            },
            allFacilities: [],
            selectedFacility: ''
        }

        this.alive = false;
    }

    componentDidMount() {
        this.alive = true;

        combineLatest(
            userAssociatedFacilities
        ).pipe(takeWhile(() => this.alive)).subscribe(([f]) => {
            if (f?.length > 0) {
                this.setState({
                    allFacilities: f,
                    loaded: true,
                });
            }
        });
    }

    componentDidMount() {
        this.alive = true;

        combineLatest(
            userAssociatedFacilities
        ).pipe(takeWhile(() => this.alive)).subscribe(([f]) => {
            if (f?.length > 0) {

                let selectedFacility = '';

                if (f.length === 1) {
                    selectedFacility = f[0].facilityModel.facilityID;
                }

                this.setState({
                    allFacilities: f,
                    selectedFacility,
                    loaded: true
                });
            }
        });
    }

    componentWillUnmount() {
        this.alive = false;
    }

    handleDateChange = (dates, selectedDateRange) => {
        this.setState({
            selectedDateRange,
            dates
        });
    }

    handleBiradChange = (selectedBirads) => {
        this.setState({
            selectedBirads
        });
    }

    handleClear = () => {
        this.setState({
            selectedBirads: [],
            selectedDates: {
                startDate: null,
                endDate: new Date()
            }
        })
    }

    handleNewDateChange = (name, date) => {
        this.setState({ selectedDates: { ...this.state.selectedDates, [name]: date } });
    }

    handleFacilityChange = (selectedFacility) => {
        this.setState({
            selectedFacility
        });
    }

    handleSubmit = () => {

        let endDate = this.state.selectedDates.endDate ? Moment(this.state.selectedDates.endDate).format("YYYY-MM-DD") : null;
        let startDate = this.state.selectedDates.startDate ? Moment(this.state.selectedDates.startDate).format("YYYY-MM-DD") : null;

        if (this.state.selectedBirads.length > 0
            && endDate && this.state.selectedFacility) {
            const q = Axios.defaults.baseURL + "api/RenderPathologyReport";

            var form = document.createElement("form");
            form.target = "_blank";
            form.method = "POST";
            form.action = q;
            form.style.display = "none";

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "startDate";
            input.value = startDate;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "endDate";
            input.value = endDate;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "birads";
            input.value = [this.state.selectedBirads.toString()];
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "facilityID";
            input.value = this.state.selectedFacility;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "ianaTimezone";
            input.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
            form.appendChild(input);

            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        } else {
            toast.error("Please select all required fields.");
        }

    }

    exportExcel = () => {
        const key = "loading";
        message.loading({ content: 'Exporting Pathology Details...', key, duration: 0 });
        let fileName = "pathology-details_" + Moment().format('YYYYMMDDHm') + '.xlsx';


        let postdata = {
            fileName,
            sDate: this.state.selectedDates.startDate ? Moment(this.state.selectedDates.startDate).format("YYYY-MM-DD") : null,
            eDate: this.state.selectedDates.endDate ? Moment(this.state.selectedDates.endDate).format("YYYY-MM-DD") : null,
            selectedBirads: this.state.selectedBirads,
            selectedFacility: this.state.selectedFacility,
            ianaTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }

        downloadPathologyDetails(postdata).then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = fileName;//"File.xlsx";
            //document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);

            this.setState({
                downloading: false
            })
        }).catch(error => {
            console.log("Error");
        });
    }


    render() {

        console.log(this.state.selectedBirads.toString());

        const { Option } = Select;

        const { RangePicker } = DatePicker;

        const theme = window.sessionStorage.getItem("theme");

        return (
            <Modal size='lg' show={this.state.showModal} onHide={this.toggleModal} backdrop='static' dialogClassName={theme === 'dark' ? "dark-theme" : 'light-theme'}>
                <Modal.Header>
                    <h1>Pathology Details</h1>
                </Modal.Header>
                <Modal.Body>
                    <div className="panel-content">
                            <div className="row">

                                <div className="form-group col col-6">
                                    <div className="form-label">Start Range</div>
                                    <DatePicker
                                        onChange={(date) => this.handleNewDateChange('startDate', date)}
                                        selectsStart
                                        startDate={this.state.selectedDates.startDate}
                                        endDate={this.state.selectedDates.endDate}
                                        className="form-control-custom"
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholderText='Start Date'
                                        selected={this.state.selectedDates.startDate && Moment(this.state.selectedDates.startDate).toDate()}
                                        customInput={
                                            <NumberFormat format="##/##/####" mask="_" />
                                        }
                                    popperProps={{
                                        positionFixed: true // use this to make the popper position: fixed
                                    }}
                                    />
                                </div>
                                <div className="col col-6">
                                <div className="form-label">End Range <span className="color-pink">*</span></div>
                                    <DatePicker
                                        onChange={(date) => this.handleNewDateChange('endDate', date)}
                                        selectsEnd
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        startDate={this.state.selectedDates.startDate}
                                        endDate={this.state.selectedDates.endDate}
                                        className="form-control-custom"
                                        placeholderText='End Date'
                                        selected={this.state.selectedDates.endDate && Moment(this.state.selectedDates.endDate).toDate()}
                                        customInput={
                                            <NumberFormat format="##/##/####" mask="_" />
                                        }
                                    popperProps={{
                                        positionFixed: true // use this to make the popper position: fixed
                                    }}
                                    />
                            </div>

                            <div className="form-group col col-lg-6 col-12">
                                <div className="form-label">Facility <span className="color-pink">*</span></div>
                                {this.state.allFacilities.length === 1 ?
                                    <div>{this.state.allFacilities[0].facilityModel.facilityName}</div>
                                    :
                                    <Select
                                        allowClear
                                        placeholder="Please select"
                                        onChange={this.handleFacilityChange}
                                        className="form-control-custom w-100"
                                        bordered={false}
                                        value={this.state.selectedFacility}
                                        showSearch
                                        virtual={false}
                                        filterOption={(input, option) => (option.children[0] + option.children[1]).toLowerCase().includes(input.toLowerCase())}
                                    >
                                        {this.state.allFacilities.map((fac, idx) => <Option key={idx} key={fac.facilityModel.facilityID} value={fac.facilityModel.facilityID}>{fac.facilityModel.facilityNumber && fac.facilityModel.facilityNumber + " - "}{fac.facilityModel.facilityName}</Option>)}
                                    </Select>
                                }
                                
                            </div>

                            <div className="form-group col col-lg-6 col-12">
                                <div className="form-label">BIRADS <span className="color-pink">*</span></div>
                                <Select
                                    mode="multiple"
                                    maxTagCount={4}
                                    allowClear
                                    placeholder="Please select"
                                    onChange={this.handleBiradChange}
                                    className="form-control-custom w-100"
                                    bordered={false}
                                    virtual={false}
                                    value={this.state.selectedBirads}
                                >
                                    <Option value="PB">PB</Option>
                                    <Option value="NB">NB</Option>
                                </Select>
                            </div>
                            </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-submit float-left" onClick={this.handleSubmit} style={{ left: '10px', position: 'fixed', backgroundColor: '#1D6F42', borderColor: '#1D6F42' }}>Export to PDF</button>
                    <div className="row">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-submit" onClick={this.exportExcel}>Run Report</button>
                            <button className="btn btn-outline-default margin-left-15" onClick={this.handleClear}>Clear</button>
                            <button className="btn btn-outline-default margin-left-15" id="closeModal" onClick={() => this.props.hideModal()}>Cancel</button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withRouter(PathologyReportModal);