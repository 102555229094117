import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import Axios from '../../config/axios';
import Globals from '../../config/globals';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { ColumnToggle } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../../css/table.css';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';

import Card from 'react-bootstrap/Card';
import '../../css/card.css';

import { SmartMessageBox } from "../../common/utils/functions";
import $ from 'jquery';
import Dropdown, { MenuItem, DropdownButton } from '@trendmicro/react-dropdown';

import { Multiselect, DropdownList, Combobox } from 'react-widgets'
import 'react-widgets/dist/css/react-widgets.css';

import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import PatientExamPane from './PatientExamPane';

import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { Select, Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
    allProviders,
    allTechnologists,
    allInsuranceCompanies,
    allDensity,
    allRecallTypes,
    allRecommendations,
    allBirads,
    allExamReasons,
    allBiopsyTypes,
    userAssociatedFacilities,
    allCategories
} from '../../common/services/ApiService';

import NumberFormat from 'react-number-format';
import { downloadExamSearchResults } from '../../common/services/ExamService';

class ExamSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPane: props.showPane,
            formFields: {
                country: 'United States',
                fName: '',
                lName: '',
                mrn: '',
                recallStartDate: null,
                recallEndDate: null,
                examStartDate: null,
                examEndDate: null,
                resultStartDate: null,
                resultEndDate: null,
            },
            insuranceCompanies: [],
            showSearchCriteria: true,
            providersList: [],
            examTypesList: [],
            sortedExamTypes: [],
            facilityList: [],
            recallTypesList: [],
            biradsList: [],
            radiologistList: [],
            densityList: [],
            allCategories: [],
            recommendationsList: [],
            examList: [],
            showPatientExam: false,
            examHistory: {},
            selectedPatientExam: {},
            technologistList: [],
            selectedFacilities: [],
            from: props.from
        }

        this.getInsuranceCompanies = this.getInsuranceCompanies.bind(this);
        this.resetSearchCriteria = this.resetSearchCriteria.bind(this);
        this.hidePane = this.hidePane.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.isDirty = false;
        this.checkOnDirty = true;
        this.allowModal = true;
        this.popEventListnerAdded = false;

    }

    componentDidMount() {
        let props = this;

        this.alive = true;

        if (!this.popEventListnerAdded) {
            $(window).on("popstate", function (event) {
                props.popEventListnerAdded = true;
                event.preventDefault();
                event.stopPropagation();
                if (props.isDirty && props.allowModal && Globals.isDirtyPage) {
                    SmartMessageBox(
                        {
                            title: "You are about to lose all changes.",
                            content:
                                "Are you sure you would like to proceed?",
                            buttons: "[No][Yes]"
                        },
                        (ButtonPressed) => {
                            if (ButtonPressed === "Yes") {
                                //window.history.back();
                                props.hidePane();
                                props.allowModal = false;
                                props.isDirty = false;
                                Globals.isDirtyPage = false;
                                return 0;
                            }
                            if (ButtonPressed === "No") {
                                window.history.pushState(null, null, null);
                                return 0;
                            }
                        }
                    );
                }
            });
        }

        combineLatest(
            allProviders,
            allTechnologists,
            allInsuranceCompanies,
            allDensity,
            allRecallTypes,
            allRecommendations,
            allBirads,
            allExamReasons,
            allBiopsyTypes,
            userAssociatedFacilities,
            allCategories
        ).pipe(takeWhile(() => this.alive)).subscribe(([x1, x2, x3, x4, x5, x6, x7, x8, x9, x10, x11]) => {

            let providersList = x1;

            for (var i = 0; i < providersList.length; i++) {
                if (providersList[i]) {
                    providersList[i].name = providersList[i].fName + ' ' + providersList[i].lName
                }
            }

            let technologistList = x2;

            for (var i = 0; i < technologistList.length; i++) {
                technologistList[i].name = technologistList[i].fName + ' ' + technologistList[i].lName
            }

            let selectedFacilities = [];
            if (x10.length === 1) {
                selectedFacilities = [x10[0].facilityModel.facilityID];
            }

            //let facilityList = x10.map(x => ({...x.facilityModel}));
        
            this.setState({
                providersList: x1,
                technologistList,
                insuranceCompanies: x3,
                densityList: x4,
                recallTypesList: x5,
                recommendationsList: x6,
                biradsList: x7,
                examReasons: x8,
                allBiopsyTypes: x9,
                listsLoaded: true,
                facilityList: x10,
                allCategories: x11,
                selectedFacilities
            })
        });

        this.getExamTypes();
        this.getRadiologists();
    }

    componentDidUpdate() {
        if (this.checkOnDirty && this.isDirty) {
            this.checkOnDirty = false;
            window.history.pushState(null, null, null);
        }
    }

    componentWillUnmount() {
        this.alive = false;
    }

    getInsuranceCompanies() {
        Axios.post(`/api/GetAllInsuranceCompanies`
        ).then(response => {
            this.setState({
                insuranceCompanies: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getExamTypes = () => {
        Axios.get(`/api/GetAllExamTypes`
        ).then(response => {
            let examTypesList = response.data;

            const sortedExamTypes = examTypesList.sort((a, b) => (a.categoryID > b.categoryID) ? 1 : -1);

            this.setState({
                examTypesList,
                sortedExamTypes
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getRadiologists = () => {
        Axios.get(`/api/GetAllRadiologists`
        ).then(response => {
            let radiologistList = response.data;

            this.setState({
                radiologistList
            });
        }).catch(error => {
            console.log(error);
        });
    }

    handleDropdownChange = (value) => {
        this.setState({
            value
        });
    }

    resetSearchCriteria() {

        if (this.isDirty || this.state.isDirty) {
            SmartMessageBox(
                {
                    title: "You are about to lose all changes.",
                    content:
                        "Are you sure you would like to proceed?",
                    buttons: "[No][Yes]"
                },
                (ButtonPressed) => {
                    this.setState({
                        formFields: {
                            country: 'United States',
                            fName: '',
                            lName: '',
                            mrn: '',
                            recallStartDate: null,
                            recallEndDate: null,
                            examStartDate: null,
                            examEndDate: null,
                            resultStartDate: null,
                            resultEndDate: null
                        },
                        selectedBirads: [],
                        selectedDensity: [],
                        selectedExamTypes: [],
                        selectedFacilities: [],
                        selectedRadiologists: [],
                        selectedRecommendations: [],
                        selectedRecallType: [],
                        selectedInsuranceCompanies: [],
                        selectedProviders: []
                    })
                    if (ButtonPressed === "No") {
                        window.history.pushState(null, null, null);
                        return 0;
                    }
                }
            );
        }
        else {
            this.setState({
                formFields: {
                    country: 'United States',
                    fName: '',
                    lName: '',
                    mrn: '',
                    recallStartDate: null,
                    recallEndDate: null,
                    examStartDate: null,
                    examEndDate: null,
                    resultStartDate: null,
                    resultEndDate: null
                },
                selectedBirads: [],
                selectedDensity: [],
                selectedExamTypes: [],
                selectedFacilities: [],
                selectedRadiologists: [],
                selectedRecommendations: [],
                selectedRecallType: [],
                selectedInsuranceCompanies: [],
                selectedProviders: []
            })
        }

    }

    hidePane() {
        if (this.state.isDirty) {
            SmartMessageBox(
                {
                    title: "You are about to lose all changes.",
                    content:
                        "Are you sure you would like to proceed?",
                    buttons: "[No][Yes]"
                },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        this.allowModal = false;
                        this.isDirty = false;
                        Globals.isDirtyPage = false;
                        this.setState({
                            showPane: false,
                            isDirty: false
                        }, () => { this.resetSearchCriteria(); this.props.hidePane(); });
                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        window.history.pushState(null, null, null);
                        return 0;
                    }
                }
            );
        }
        else {
            this.isDirty = false;
            this.setState({
                showPane: false,
                isDirty: false
            }, () => { this.resetSearchCriteria(); this.props.hidePane(); });
           
        }
    }

    handleDateChange = (name, date) => {
        this.setState({ formFields: { ...this.state.formFields, [name]: date } }, () => {
            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                this.isDirty = true;  // dirty
            }

        });
    }

    handleInputChange(event) {
        const target = event.target;
        var value = null;
        if (target.type === 'checkbox') {
            value = target.checked === true ? 1 : 0;
        }
        else {
            value = target.value;
        }
        const name = target.name;
        this.setState({ formFields: { ...this.state.formFields, [name]: value } }, () => {
            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                this.isDirty = true;  // dirty
                Globals.isDirtyPage = true;
            }

        });
    }

    handleSubmit() {
        this.setState({ loadingResults: true });
        let postdata = {
            patientModel: {
                fName: this.state.formFields.fName,
                lName: this.state.formFields.lName,
                mrn: this.state.formFields.mrn
            },
            patientExamResultModel: {
                recallTypeID: this.state.selectedRecallType || null,
                internalExam: this.state.formFields.internalExam !== null && this.state.formFields.internalExam === 'yes' ? 1 : (this.state.formFields.internalExam === 'no' ? 0 : null),
                losttoFollowUp: this.state.formFields.losttoFollowUp ? '1' : (this.state.formFields.losttoFollowUp === false ? '0' : null)
            },
            providerID: this.state.selectedProviders || null,
            patientExamModel: {
                isExamComplete: this.state.formFields.examComplete === 'yes' ? 1 : (this.state.formFields.examComplete === 'no' ? 0 : null),
                performingTechUserID: this.state.selectedTechnologist || null
            },
            facilityList: this.state.selectedFacilities || [],
            insuranceList: this.state.selectedInsuranceCompanies || [],
            examTypeList: this.state.selectedExamTypes || [],
            biradList: this.state.selectedBirads || [],
            radiologistList: this.state.selectedRadiologists || [],
            densityList: this.state.selectedDensity || [],
            recommendationList: this.state.selectedRecommendations || [],
            recallStartDate: this.state.formFields.recallStartDate,
            recallEndDate: this.state.formFields.recallEndDate,
            examStartDate: this.state.formFields.examStartDate,
            examEndDate: this.state.formFields.examEndDate,
            resultStartDate: this.state.formFields.resultStartDate,
            resultEndDate: this.state.formFields.resultEndDate
        }

        Axios.post(`/api/GetExamsByLookup`, postdata
        ).then(response => {
            Globals.isDirtyPage = false;
            this.setState({
                examList: response.data,
                showSearchCriteria: false,
                isDirty: false,
                loadingResults: false
            });
        }).catch(error => {
            this.setState({
                loadingResults: false
            });
        });
    }

    handleFilter = (input, option) => {

    }

    handleExamExport = () => {
        const key = "loading";
        message.loading({ content: 'Exporting Exam Search Results...', key, duration: 0 });
        let fileName = "exam-results_" + Moment().format('YYYYMMDDHm') + '.xlsx';

        let searchCriteria = {
            patientModel: {
                fName: this.state.formFields.fName,
                lName: this.state.formFields.lName,
                mrn: this.state.formFields.mrn
            },
            patientExamResultModel: {
                recallTypeID: this.state.selectedRecallType || null,
                internalExam: this.state.formFields.internalExam !== null && this.state.formFields.internalExam === 'yes' ? 1 : (this.state.formFields.internalExam === 'no' ? 0 : null),
                losttoFollowUp: this.state.formFields.losttoFollowUp ? '1' : (this.state.formFields.losttoFollowUp === false ? '0' : null)
            },
            providerID: this.state.selectedProviders || null,
            patientExamModel: {
                isExamComplete: this.state.formFields.examComplete === 'yes' ? 1 : (this.state.formFields.examComplete === 'no' ? 0 : null),
                performingTechUserID: this.state.selectedTechnologist || null
            },
            facilityList: this.state.selectedFacilities || [],
            insuranceList: this.state.selectedInsuranceCompanies || [],
            examTypeList: this.state.selectedExamTypes || [],
            biradList: this.state.selectedBirads || [],
            radiologistList: this.state.selectedRadiologists || [],
            densityList: this.state.selectedDensity || [],
            recommendationList: this.state.selectedRecommendations || [],
            recallStartDate: this.state.formFields.recallStartDate,
            recallEndDate: this.state.formFields.recallEndDate,
            examStartDate: this.state.formFields.examStartDate,
            examEndDate: this.state.formFields.examEndDate,
            resultStartDate: this.state.formFields.resultStartDate,
            resultEndDate: this.state.formFields.resultEndDate
        }

        downloadExamSearchResults(this.state.examList, fileName, searchCriteria).then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = fileName;//"File.xlsx";
            //document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);

            this.setState({
                downloading: false
            })
        }).catch(error => {
            console.log("Error");
        });
    }

    render() {

        const { ToggleList } = ColumnToggle;

        function checkboxFormatter(cell, row) {
            return (
                <div className="form-control-custom no-border">
                    <div className="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" className="custom-control-input" value={cell} defaultChecked={cell === 1 ? true : false} />
                        <label className="custom-control-label"></label>
                    </div>
                </div>
            );
        }

        let state = this.state;

        function radiologistFormatter(cell, row) {
            return (
                <span>{row.radiologistModel && (row.radiologistModel.fName + ' ' + row.radiologistModel.lName)}</span>
            );
        }

        const columns = [{
            dataField: 'patientModel.fName',
            text: 'Patient Name',
            formatter: (cell, row) => <span>{row.patientModel.fName} {row.patientModel.lName}</span>
        }, {
            dataField: 'patientModel.mrn',
            text: 'MRN #'
            }, {
                dataField: 'facilityName',
                text: 'Facility'
            },  {
            dataField: 'recallTypesModel.recallTypeDescription',
            text: 'Recall Type'
        }, {
            dataField: 'patientExamResultModel.recallDate',
            text: 'Recall Date',
            formatter: cell => cell && Moment(cell).utc().format('L')
        }, {
            dataField: 'patientExamModel.examDateTime',
            text: 'Exam Date',
            formatter: cell => Moment(cell).utc().format('L')
        }, {
            dataField: 'insuranceModel.insCompName',
            text: 'Insurance'
        }, {
            dataField: 'providerModel.fName',
            text: 'Referring MD',
            formatter: (cell, row) => <span>{row.providerModel.fName} {row.providerModel.lName}</span>
        }, {
            dataField: 'technologistModel.fName',
            text: 'Tech',
            formatter: (cell, row) => <span>{row.technologistModel.fName} {row.technologistModel.lName}</span>
        }, {
            dataField: 'examTypeModel.examType',
            text: 'Exam Type'
        }, {
            dataField: 'patientExamResultModel.resultDate',
            text: 'Result Date',
            formatter: cell => Moment(cell).utc().format('L')
        }, {
            dataField: 'patientExamResultModel.birad',
            text: 'BIRADS',
        }, {
            dataField: 'radiologistModel.radiologistID',
            text: 'Rad',
            formatter: radiologistFormatter
        }, {
            dataField: 'densityModel.densityDescription',
            text: 'Density'
        }, {
            dataField: 'recommendationsModel.description',
            text: 'Rec'
        }, {
            dataField: 'patientExamResultModel.internalExam',
            text: 'I/E',
            formatter: cell => cell === 1 ? "Internal" : "External"
        }, {
            dataField: 'patientExamResultModel.losttoFollowUp',
                text: 'Non-Compliant',
            formatter: cell => cell === '1' ? "Yes" : "No"
        },  {
            dataField: 'patientExamModel.isExamCompleteYN',
            text: 'Complete',
            formatter: checkboxFormatter
        }];

        const pagination = {
            sizePerPage: 5,
            pageStartIndex: 1,
            alwaysShowAllBtns: true,
            sizePerPageList: [{ text: "5", value: 5 }, { text: "10", value: 10 }, { text: "50", value: 50 }, { text: "100", value: 100 }], // A numeric array is also available. the purpose of above example is custom the text
            showTotal: true
        };

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            hideSelectColumn: true
        };

        const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                let postdata = {
                    uniqueID: row.patientExamModel.patientExamID,
                    facilityID: row.patientExamModel.facilityID,
                    name: row.examTypeModel.examclass,
                    userID: userInfo.userId
                };

                Axios.post(`/api/GetPatientExamResults`, postdata
                ).then(response => {

                    this.setState({
                        selectedPatientExam: row,
                        examResults: response.data.resultInfo,
                        sharedFollowUpIDs: response.data.examIDs,
                        showPatientExam: true
                    });

                }).catch(error => {
                    console.log(error);
                });
            }
        };

        const { Option } = Select;
        const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff !important' }} spin />;

        const theme = window.sessionStorage.getItem("theme");

        //const sortedExamTypes = this.state.examTypesList.sort((a, b) => (a.categoryID > b.categoryID) ? 1 : -1);

        return (
            <SlidingPane
                className={theme === 'dark' ? "dark-theme" : 'light-theme'}
                overlayClassName='pinkCard'
                isOpen={this.state.showPane}
                title='Exam Details'
                onRequestClose={this.hidePane}
                data-theme="dark"
                className="w-100"
            >
                <div className="slide-pane-body" >
                    <div className="margin-bottom-15rem pinkTextA">
                        {this.state.showSearchCriteria ?
                            <span onClick={() => this.setState({ showSearchCriteria: false })}>Collapse Search Criteria <i className="far fa-angle-up"></i></span>
                            :
                            <span onClick={() => this.setState({ showSearchCriteria: true })}>Expand Search Criteria <i className="far fa-angle-down"></i></span>


                        }
                    </div>
                    {this.state.showSearchCriteria &&
                        <div>

                            <Card className="pinkCard">
                                <Card.Header><span>Patient</span>
                                </Card.Header>
                                <Card.Body>

                                    <div className="row">
                                        <div className="col col-md-4 form-group">
                                            <label className="form-label">Last Name</label>
                                            <input type="text" id="" name="lName" value={this.state.formFields.lName} onChange={this.handleInputChange} className="form-control-custom" />
                                        </div>

                                        <div className="col col-md-4 form-group">
                                            <label className="form-label">First Name</label>
                                            <input type="text" name="fName" value={this.state.formFields.fName} onChange={this.handleInputChange} className="form-control-custom" />
                                        </div>

                                        <div className="col col-md-4 form-group">
                                            <label className="form-label">MRN</label>
                                            <input type="text" name="mrn" value={this.state.formFields.mrn} onChange={this.handleInputChange} className="form-control-custom" />
                                        </div>

                                        <div className="form-group col-lg-4">
                                        <label className="form-label">Facility</label>
                                        {this.state.facilityList.length === 1 ?
                                            <div>{this.state.facilityList[0].facilityModel.facilityName}</div>
                                            :
                                            <Select
                                                mode="multiple"
                                                maxTagCount={2}
                                                onChange={(selectedFacilities) => this.setState({ selectedFacilities })}
                                                allowClear
                                                placeholder="Please select 1 or more"
                                                className="form-control-custom w-100"
                                                bordered={false}
                                                value={this.state.selectedFacilities}
                                                showSearch
                                                virtual={false}
                                                filterOption={(input, option) => (option.children[0] + option.children[1]).toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {this.state.facilityList.map((fac, idx) => <Option key={idx} key={fac.facilityModel.facilityID} value={fac.facilityModel.facilityID}>{fac.facilityModel.facilityNumber && fac.facilityModel.facilityNumber + " - "}{fac.facilityModel.facilityName}</Option>)}
                                            </Select>
                                        }
                                        
                                            
                                        </div>

                                        <div className="form-group col-lg-4">
                                            <label className="form-label">Recall Type</label>

                                        <Select
                                            onChange={selectedRecallType => this.setState({ selectedRecallType, isDirty: true })}
                                            allowClear
                                            placeholder="Please select 1"
                                            className="form-control-custom w-100"
                                            bordered={false}
                                            virtual={false}
                                        >
                                            {this.state.recallTypesList?.map((rt, idx) => <Option key={idx} key={rt.recallTypeID} value={rt.recallTypeID}>{rt.recallTypeDescription}</Option>)}
                                        </Select>
                                           
                                        </div>

                                        <div className="form-group col-lg-4">
                                            <label className="form-label">Recall Date</label>
                                            <DatePicker
                                                onChange={(date) => this.handleDateChange('recallStartDate', date)}
                                                selectsStart
                                                startDate={this.state.formFields.recallStartDate}
                                                endDate={this.state.formFields.recallEndDate}
                                            className="form-control-custom"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            selected={this.state.formFields.recallStartDate && Moment(this.state.formFields.recallStartDate).toDate()}
                                            placeholderText='Start Date'
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            }
                                            />
                                        </div>
                                        <div className="form-group offset-lg-8 col-lg-4">
                                            <DatePicker
                                                onChange={(date) => this.handleDateChange('recallEndDate', date)}
                                            selectsEnd
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                                startDate={this.state.formFields.recallStartDate}
                                                endDate={this.state.formFields.recallEndDate}
                                                minDate={this.state.formFields.recallStartDate}
                                                className="form-control-custom"
                                            selected={this.state.formFields.recallEndDate && Moment(this.state.formFields.recallEndDate).toDate()}
                                            placeholderText='End Date'
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            }
                                            />
                                        </div>
                                    </div>

                                </Card.Body>
                            </Card>

                            <Card className="pinkCard">
                                <Card.Header><span>Exams</span>
                                </Card.Header>
                                <Card.Body>

                                    <div className="row">

                                        <div className="form-group col-lg-4">
                                            <label className="form-label">Exam Date</label>
                                            <DatePicker
                                                onChange={(date) => this.handleDateChange('examStartDate', date)}
                                            selectsStart
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                                startDate={this.state.formFields.examStartDate}
                                                endDate={this.state.formFields.examEndDate}
                                                className="form-control-custom margin-bottom-15rem"
                                                selected={this.state.formFields.examStartDate && Moment(this.state.formFields.examStartDate).toDate()}
                                            placeholderText='Start Date'
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            }
                                            />

                                            <DatePicker
                                                onChange={(date) => this.handleDateChange('examEndDate', date)}
                                            selectsEnd
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                                startDate={this.state.formFields.examStartDate}
                                                endDate={this.state.formFields.examEndDate}
                                                minDate={this.state.formFields.examStartDate}
                                                className="form-control-custom"
                                                selected={this.state.formFields.examEndDate && Moment(this.state.formFields.examEndDate).toDate()}
                                            placeholderText='End Date'
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            }
                                            />
                                        </div>

                                        <div className="form-group col-lg-4">
                                            <label className="form-label">Insurance</label>
                                            <Select
                                                mode="multiple"
                                                maxTagCount={2}
                                                onChange={(selectedInsuranceCompanies) => this.setState({ selectedInsuranceCompanies })}
                                                allowClear
                                                placeholder="Please select 1 or more"
                                                className="form-control-custom w-100"
                                            bordered={false}
                                            virtual={false}
                                                value={this.state.selectedInsuranceCompanies}
                                            >
                                                {this.state.insuranceCompanies.map((i, idx) => <Option key={idx} key={i.insuranceID} value={i.insuranceID}>{i.insCompName}</Option>)}
                                            </Select>
                                        </div>

                                        <div className="form-group col-lg-4">
                                        <label className="form-label">Referring MD</label>
                                        <Select
                                            onChange={selectedProviders => this.setState({ selectedProviders, isDirty: true })}
                                            allowClear
                                            placeholder="Please select 1"
                                            className="form-control-custom w-100"
                                            bordered={false}
                                            virtual={false}
                                        >
                                            {this.state.providersList.map((item, idx) => <Option key={idx} key={item.providerID} value={item.providerID}>{item.fName} {item.lName}</Option>)}
                                        </Select>
                                        </div>



                                        <div className="col col-md-4 form-group">
                                        <label className="form-label">Technologist</label>
                                            <Select
                                            showSearch
                                            onChange={selectedTechnologist => this.setState({ selectedTechnologist, isDirty: true })}
                                            className="form-control-custom w-100"
                                            bordered={false}
                                            placeholder="Select a Technologist"
                                            virtual={false}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            >
                                            {this.state.technologistList.map((item, idx) => <Option key={idx} key={item.userID} value={item.userID}>{item.name}</Option>)}
                                            </Select>

                                            {/*<Combobox*/}
                                            {/*data={this.state.technologistList}*/}
                                            {/*textField='name'*/}
                                            {/*value={this.state.selectedTechnologist}*/}
                                            {/*onChange={selectedTechnologist => this.setState({ selectedTechnologist, isDirty: true })}*/}
                                            {/*suggest={true}*/}
                                            {/*/>*/}
                                        </div>

                                        <div className="form-group col-lg-4">
                                        <label className="form-label">Exam Type</label>
                                        <Select
                                            mode="multiple"
                                            maxTagCount={2}
                                            onChange={(selectedExamTypes) => this.setState({ selectedExamTypes, isDirty: true })}
                                            allowClear
                                            virtual={false}
                                            placeholder="Please select 1 or more"
                                            className="form-control-custom w-100"
                                            bordered={false}
                                            value={this.state.selectedExamTypes}
                                        >
                                            {this.state.sortedExamTypes.map((item, idx) => (
                                                <Fragment>
                                                    {item.categoryID !== this.state.sortedExamTypes[idx - 1]?.categoryID &&
                                                        <Option disabled key={item.categoryID} className="color-pink">{this.state.allCategories?.find(cat => cat.categoryID === item.categoryID)?.category}</Option>
                                                    }
                                                    <Option title={"Hello"} key={idx} key={item.examTypeID} value={item.examTypeID}>{item.examType}</Option>
                                                </Fragment>
                                            ))}
                                            
                                        </Select>

                                          
                                        </div>

                                        <div className="form-group col-4">
                                            <label className="form-label">Exam Complete</label>

                                            <div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" name="examComplete" className="custom-control-input" value="yes" onChange={this.handleInputChange} checked={this.state.formFields.examComplete === "yes"} />
                                                    <span className="custom-control-label">Yes</span>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" name="examComplete" className="custom-control-input" value="no" onChange={this.handleInputChange} checked={this.state.formFields.examComplete === "no"} />
                                                    <span className="custom-control-label">No</span>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </Card.Body>
                            </Card>

                            <Card className="pinkCard">
                                <Card.Header><span>Results</span>
                                </Card.Header>
                                <Card.Body>

                                    <div className="row">

                                        <div className="form-group col-lg-4">
                                            <label className="form-label">Result Date</label>
                                            <DatePicker
                                                onChange={(date) => this.handleDateChange('resultStartDate', date)}
                                            selectsStart
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                                startDate={this.state.formFields.resultStartDate}
                                                endDate={this.state.formFields.resultEndDate}
                                                className="form-control-custom margin-bottom-15rem"
                                                selected={this.state.formFields.resultStartDate && Moment(this.state.formFields.resultStartDate).toDate()}
                                            placeholderText='Start Date'
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            }
                                            />

                                            <DatePicker
                                                onChange={(date) => this.handleDateChange('resultEndDate', date)}
                                            selectsEnd
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                                startDate={this.state.formFields.resultStartDate}
                                                endDate={this.state.formFields.resultEndDate}
                                                minDate={this.state.formFields.resultStartDate}
                                                className="form-control-custom"
                                                selected={this.state.formFields.resultEndDate && Moment(this.state.formFields.resultEndDate).toDate()}
                                            placeholderText='End Date'
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            }
                                            />
                                        </div>

                                        <div className="form-group col-lg-4">
                                        <label className="form-label">BIRADS Code</label>
                                        <Select
                                            mode="multiple"
                                            maxTagCount={2}
                                            onChange={(selectedBirads) => this.setState({ selectedBirads, isDirty: true })}
                                            allowClear
                                            placeholder="Please select 1 or more"
                                            className="form-control-custom w-100"
                                            bordered={false}
                                            virtual={false}
                                            value={this.state.selectedBirads}
                                        >
                                            {this.state.biradsList.map((item, idx) => <Option key={idx} key={item.birads} value={item.birads}>{item.biradsDescription}</Option>)}
                                        </Select>
                                        </div>

                                        <div className="form-group col-lg-4">
                                            <label className="form-label">Radiologist</label>
                                        <Select
                                            mode="multiple"
                                            maxTagCount={2}
                                            onChange={(selectedRadiologists) => this.setState({ selectedRadiologists, isDirty: true })}
                                            allowClear
                                            placeholder="Please select 1 or more"
                                            className="form-control-custom w-100"
                                            bordered={false}
                                            virtual={false}
                                            value={this.state.selectedRadiologists}
                                        >
                                            {this.state.radiologistList.map((item, idx) => <Option key={idx} key={item.radiologistID} value={item.radiologistID}>{item.fName} {item.lName}</Option>)}
                                        </Select>
                                        </div>

                                        <div className="form-group col-lg-4">
                                        <label className="form-label">Breast Density</label>
                                        <Select
                                            mode="multiple"
                                            maxTagCount={1}
                                            onChange={(selectedDensity) => this.setState({ selectedDensity, isDirty: true })}
                                            allowClear
                                            placeholder="Please select 1 or more"
                                            className="form-control-custom w-100"
                                            bordered={false}
                                            virtual={false}
                                            value={this.state.selectedDensity}
                                        >
                                            {this.state.densityList.map((item, idx) => <Option key={idx} key={item.breastDensityID} value={item.breastDensityID}>{item.densityDescription}</Option>)}
                                        </Select>
                                        </div>

                                        <div className="form-group col-lg-4">
                                        <label className="form-label">Recommendation</label>
                                        <Select
                                            mode="multiple"
                                            maxTagCount={1}
                                            onChange={(selectedRecommendations) => this.setState({ selectedRecommendations, isDirty: true })}
                                            allowClear
                                            placeholder="Please select 1 or more"
                                            className="form-control-custom w-100"
                                            bordered={false}
                                            virtual={false}
                                            value={this.state.selectedRecommendations}
                                        >
                                            {this.state.recommendationsList.map((item, idx) => <Option key={idx} key={item.recommendationID} value={item.recommendationID}>{item.description}</Option>)}
                                        </Select>
                                        </div>

                                        <div className="form-group col-2">
                                            <label className="form-label">Internal Exam</label>

                                            <div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" name="internalExam" className="custom-control-input" value="yes" onChange={this.handleInputChange} checked={this.state.formFields.internalExam === "yes"} />
                                                    <span className="custom-control-label">Internal</span>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" name="internalExam" className="custom-control-input" value="no" onChange={this.handleInputChange} checked={this.state.formFields.internalExam === "no"} />
                                                    <span className="custom-control-label">External</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group col-2">
                                            <label className="form-label">Lost to Follow Up</label>
                                            <div className="form-control-custom no-border">
                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" className="custom-control-input" id="losttoFollowUp" name="losttoFollowUp" value={this.state.formFields.losttoFollowUp || ''} onChange={this.handleInputChange} checked={this.state.formFields.losttoFollowUp || false} />
                                                    <label className="custom-control-label"></label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </Card.Body>
                            </Card>
                        </div>
                    }

                    {this.state.examList.length > 0 ?
                        <div className="fullTable">
                            <div className="float-right">
                                <button type="button" className="pinkTextA btn btn-outline-default margin-0-10" onClick={this.handleExamExport}>
                                    Export List
                            </button>
                            </div>
                            <ToolkitProvider
                                keyField='patientExamModel.patientExamID'
                                data={this.state.examList}
                                columns={columns}
                                columnToggle
                            >
                                {
                                    props => (
                                        <div>
                                            <ToggleList {...props.columnToggleProps} />
                                            <hr />
                                            <BootstrapTable
                                                pagination={paginationFactory(pagination)}
                                                {...props.baseProps}
                                                rowEvents={rowEvents}
                                                selectRow={selectRow}
                                                hover condensed />
                                        </div>
                                    )
                                }
                            </ToolkitProvider>
                        </div>
                        :
                        <em>No exams associated to this patient</em>
                    }
                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        <button className="btn btn-submit" onClick={this.handleSubmit}>{this.state.loadingResults ? <span><Spin indicator={antIcon} className="text-white" /></span> : <span>Search</span>}</button>
                        <button className="btn btn-outline-default margin-left-15" onClick={this.hidePane}>Cancel</button>
                        <button className="pinkTextA btn btn-outline-default float-right" onClick={this.resetSearchCriteria}>Reset Search Criteria</button>
                    </div>
                </div>
                {this.state.showPatientExam && <PatientExamPane showPane={this.state.showPatientExam}
                    examHistory={this.state.examResults} examInfo={this.state.selectedPatientExam} from='general'
                    hidePane={() => this.setState({ showPatientExam: false })} sharedFollowUpIDs={this.state.sharedFollowUpIDs}
                    updateExamInfo={this.handleSubmit} />}
            </SlidingPane>
        );
    }
}

export default withRouter(ExamSearch);