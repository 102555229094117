import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from "react-hook-form";

import { toast, confirm } from '@rickylandino/react-messages';
import NumberFormat from 'react-number-format';
import Moment from 'moment';

import Card from 'react-bootstrap/Card';
import '../../../css/card.css';

import { message } from 'antd';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { getImportAuditResults, getAuditResultsByImportID } from '../../../common/services/AuditService';
import { exportAuditList } from '../../../common/services/ApiService';

import { Modal } from 'react-bootstrap';


function ViewImportAuditHistory(props) {

    const [state, setState] = useState({
        auditInfo: [],
        loaded: false
    });

    const [modalInfo, setModalInfo] = useState({
        show: false,
        data: []
    });

    var searchString = useRef("");

    useEffect(() => {
        var alive = true;

        getImportAuditResults().then(data => {
            if (alive) {
                setState({
                    auditInfo: data,
                    loaded: true
                });
            }
        });

        return () => { alive = false };
    }, []);

    function handleAuditExport() {
        const key = "loading";
        message.loading({ content: 'Exporting Audit Results...', key, duration: 0 });
        let fileName = "audit-results_" + Moment().format('YYYYMMDDHm') + '.xlsx';

        var filteredresults = state.auditInfo;

        let searchQuery = searchString.current;

        if (searchQuery) {
            filteredresults = state.auditInfo.filter((item) => {
                if (item.userName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    item.eventType?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    item.mrn?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    (item.eventTime && Moment(new Date(item.eventTime)).utc().format('lll')?.toLowerCase().includes(searchQuery.toLowerCase())) ||
                    (item.examDateTime && Moment(new Date(item.examDateTime)).utc().format('lll')?.toLowerCase().includes(searchQuery.toLowerCase())) ||
                    (item.resultDate && Moment(new Date(item.resultDate)).utc().format('lll')?.toLowerCase().includes(searchQuery.toLowerCase()))
                ) {
                    return item;
                }
            });
        }

        let postdata = {
            results: filteredresults,
            searchCriteria: searchQuery
        }

        exportAuditList(postdata).then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = fileName;//"File.xlsx";
            //document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);
        }).catch(error => {
            console.log("Error");
        });
    }

    const columns = [{
        dataField: 'eventTime',
        text: 'EventTime',
        formatter: cell => Moment(new Date(cell)).utc().format('lll'),
        filterValue: cell => Moment(new Date(cell)).utc().format('lll'),
    }, {
        dataField: 'fileName',
        text: 'File Name'
    }, {
        dataField: 'facilityName',
        text: 'Facility'
    }];

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        hideSelectColumn: true
    };

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            getAuditResultsByImportID(row.importAuditingID).then(data => {
                setModalInfo({
                    show: true,
                    data
                });
            });
        }
    };

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={text}
            role="presentation"
            className="form-control-custom hover"
            tabIndex="-1"
            data-page={page}
            onMouseDown={(e) => {
                e.preventDefault();
                onSizePerPageChange(page);
            }}
            style={{ color: 'pink' }}
        >
            <span className="a hover"
                tabIndex="-1"
                role="menuitem"
                data-page={page}
                onMouseDown={(e) => {
                    e.preventDefault();
                    onSizePerPageChange(page);
                }}
                style={{ color: 'rgba(243, 0, 121, 1)' }}
            >
                {text}
            </span>
        </li>
    );

    const pagination = {
        sizePerPage: 10,
        sizePerPageList: [{ text: "5", value: 5 }, { text: "10", value: 10 }, { text: "50", value: 50 }, { text: "100", value: 100 }], // A numeric array is also available. the purpose of above example is custom the text
        sizePerPageOptionRenderer
    };

    const rowClasses = (row, rowIndex) => {

        if (row.inactiveYN) {
            return "user-inactive";
        }

    };

    const modalColumns = [{
        dataField: 'eventTime',
        text: 'EventTime',
        formatter: cell => Moment(new Date(cell)).utc().format('lll'),
        filterValue: cell => Moment(new Date(cell)).utc().format('lll'),
    }, {
        dataField: 'userName',
        text: 'User Name'
    }, {
        dataField: 'eventType',
        text: 'Event Type'
    }, {
        dataField: 'mrn',
        text: 'MRN'
    }, {
        dataField: 'examDateTime',
        text: 'Exam Date/Time',
        formatter: cell => cell && Moment(new Date(cell)).utc().format('lll'),
        filterValue: cell => cell && Moment(new Date(cell)).utc().format('lll'),
    }, {
        dataField: 'resultDate',
        text: 'Result Date/Time',
        formatter: cell => cell && Moment(new Date(cell)).utc().format('lll'),
        filterValue: cell => cell && Moment(new Date(cell)).utc().format('lll'),
    }];

    const MySearch = (props) => {
        let input;
        const handleClick = () => {
            searchString.current = input.value;

            props.onSearch(input.value);
        };
        return (
            <div>
                <input
                    className="form-control-custom"
                    ref={n => input = n}
                    type="text"
                    onChange={handleClick}
                />
            </div>
        );
    };

    return (
        <div>
            <div className="panel-hdr justify-content-end">
                <button type="button" className="pinkTextA btn btn-outline-default margin-0-10" onClick={handleAuditExport}>
                    Export List
                </button>
            </div>

            {state.loaded &&
                <Card className="pinkCard">
                    <Card.Header><span>Audit History</span>
                    </Card.Header>
                    <Card.Body>
                        <div className="fullTable">
                            <ToolkitProvider
                            keyField='tempID'
                            data={state.auditInfo}
                            columns={columns}
                            columnToggle
                            search
                            >
                                {
                                    props => (
                                        <div>
                                            <MySearch  {...props.searchProps} />
                                            <hr />
                                            <BootstrapTable
                                                pagination={paginationFactory(pagination)}
                                                {...props.baseProps}
                                                rowEvents={rowEvents}
                                                selectRow={selectRow}
                                                rowClasses={rowClasses}
                                                hover condensed />
                                        </div>
                                    )
                                }
                            </ToolkitProvider>
                        </div>
                    </Card.Body>

                </Card>
            }

            <Modal size='lg' show={modalInfo.show} backdrop='static' onHide={() => setModalInfo({ show: false, data: [] })}>
                <Modal.Header>
                    <h1>Info</h1>
                </Modal.Header>
                <Modal.Body>
                    <div className="panel-content">
                        <div className="row">
                            <ToolkitProvider
                                keyField='tempID'
                                data={modalInfo.data}
                                columns={modalColumns}
                                columnToggle
                                search
                            >
                                {
                                    props => (
                                        <div>
                                            <MySearch  {...props.searchProps} />
                                            <hr />
                                            <BootstrapTable
                                                pagination={paginationFactory(pagination)}
                                                {...props.baseProps}
                                                rowEvents={rowEvents}
                                                selectRow={selectRow}
                                                rowClasses={rowClasses}
                                                hover condensed />
                                        </div>
                                    )
                                }
                            </ToolkitProvider>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-submit">Save</button>
                            <button className="btn btn-outline-default margin-left-15" id="closeModal" onClick={() => setModalInfo({ show: false, data: [] })}>Cancel</button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default withRouter(ViewImportAuditHistory);