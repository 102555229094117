import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReportTemplateTable from './ReportTemplateTable';
import Axios from '../../../config/axios';
import NumberFormat from 'react-number-format'
import { Button, Modal } from 'react-bootstrap';
import { MyToast } from '../../Helpers/Toast';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ReportTemplates extends Component {

    constructor(props) {
        super(props);

        this.state = {
            templates: [],
            template: null,
            loading: true,
            loadTemplate: true,
            showModal: false,
            formFields: {
                templateID: "00000000-0000-0000-0000-000000000000",
                templateName: "",
                templateType: "",
                templateClass: "",
                templateHtml: "",
                inactiveYN: 0
            },
            showToast: false,
            toastContent: {
                title: '',
                content: ''
            },
            editorContent: ''
        }




        this.handleClick = this.handleClick.bind(this);
        this.GetAllTemplates = this.GetAllTemplates.bind(this);
        this.addNew = this.addNew.bind(this);
        this.toggleToast = this.toggleToast.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.notifyFail = this.notifyFail.bind(this);
        this.notifySuccess = this.notifySuccess.bind(this);

    }

    componentDidMount() {
        this.GetAllTemplates();
    }

    notifySuccess = () => toast.success('Template updated.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
    });

    notifyFail = () => toast.error('Something went wrong', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
    });

    handleClick(event) {
        event.preventDefault();

        switch (event.target.id) {
            case 'backToResults':

                this.setState({
                    patient: null,
                    loading: true,
                    loadPatient: true
                }, () => this.GetAllPatients());
                break;
            case 'closeModal':
                this.setState({
                    showModal: !this.state.showModal
                });
                break;
            default:
                break;
        }
    }

    editTemplate(template) {

        //console.log(template)

        this.setState({
            formFields: template,
            //editorContent: '<div>Hello</div>',
            editorContent: template.templateHtml,
            showModal: true
        });
    }

    addNew(event) {
        event.preventDefault();

        this.setState({
            formFields: {
                templateID: "00000000-0000-0000-0000-000000000000",
                templateName: "",
                templateType: "",
                templateClass: "",
                templateHtml: "",
                inactiveYN: 0
            },
            //showModal: !this.state.showModal
            showModal: true
        });
    }

    toggleToast(toastContent) {
        if (toastContent) {
            this.setState({
                showToast: !this.state.showToast,
                toastContent
            });
        } else {
            this.setState({
                showToast: !this.state.showToast
            });
        }
    }

    GetAllTemplates() {
        Axios.get(`/api/GetAllTemplates`
        ).then(response => {
            console.log(response.data)
            this.setState({
                templates: response.data,
                loading: false
            });
        }).catch(error => {

            console.log(error);
        });
    }

    handleInputChange(event) {
        const target = event.target;
        var value = "";
        if (target.type === 'checkbox') {
            value = target.checked === true ? "1" : "0";
        }
        else {
            value = target.value;
        }
        const name = target.name;

        console.log(name);
        console.log(value);

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    handleSubmit(event) {
        event.preventDefault();

        let ffCopy = { ...this.state.formFields };

        let postdata = ffCopy

        let toastContent = {
            title: '',
            content: ''
        };

        if (ffCopy.templateID && ffCopy.templateID !== "00000000-0000-0000-0000-000000000000") {
            //if updating current entry
            Axios.post(`/api/UpdateTemplate`, postdata
            ).then(response => {
                toastContent.title = 'Success';
                toastContent.content = "Template has been updated."
                this.toggleToast(toastContent);
                this.setState({
                    showModal: !this.state.showModal
                });
                this.GetAllTemplates();
            }).catch(error => {
                this.notifyFail('Fail');
                console.log(error);
            });
        } else {
            //if new entry
            Axios.post(`/api/InsertTemplate`, postdata
            ).then(response => {
                toastContent.title = 'Success';
                toastContent.content = "Template has been added."
                this.toggleToast(toastContent);
                this.setState({
                    showModal: !this.state.showModal
                });
                this.GetAllTemplates();
            }).catch(error => {
                console.log(error);
            });
        }
    }

    //handleEditorChange = (content, editor) => {

    //    //console.log(content);

    //    //this.setState({
    //    //    editorContent: content
    //    //    formFields: { ...this.state.formFields, [name]: value }
    //    //});

    //    this.setState({ formFields: { ...this.state.formFields, 'templateHtml': content } });
    //}

    render() {
        return (
            <div>
                {
                    this.state.loadTemplate &&
                        <div className="row">
                            <div className="col-lg-12" >
                                <div className="panel-hdr">
                                    <h2>
                                        Report Templates
                                    </h2>

                                    <button type="button" onClick={this.addNew} className="btn btn-outline-primary margin-0-10">
                                        New
                                    </button>
                                </div>
                                <div className="frame-wrap">
                                    {!this.state.loading && !this.state.showModal &&
                                        <ReportTemplateTable data={this.state.templates} editTemplate={(template) => this.editTemplate(template)} />
                                    }
                                </div>
                            </div >
                        </div >
                }

                {this.state.showModal &&
                    <div style={{ backgroundColor: 'gainsboro', padding: '10px' }}>
                        <div className="panel-content">
                            <form>
                                <div className="row">
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Template Name</label>
                                        <input type="text" name="templateName" value={this.state.formFields.templateName || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                    </div>
                                </div>
                                <br />
                                <br />
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <label className="form-label">Template Html</label>
                                        <textarea
                                            value={this.state.formFields.templateHtml}
                                            onChange={this.handleInputChange}
                                            className="form-control"
                                            placeholder=""
                                            rows="20"
                                            maxLength="90000"
                                            name="templateHtml"
                                        />

                                    </div>
                                </div>
                            </form>
                        </div>
                        <button type="button" onClick={this.handleSubmit} className="btn btn-primary">
                            Save
                        </button>
                        &nbsp;<a id="closeModal" onClick={this.handleClick}>Cancel</a>

                    </div>
                }

            </div>
        );
    }
}

export default withRouter(ReportTemplates);