import Axios from '../../config/axios';

export function insertWhatsNewPost(postdata) {
    return Axios.post(`/api/InsertWhatsNewPost`, postdata
    ).then(response => response.data).catch(error => {
        console.log(error);
    });
}

export function getMostRecentWhatsNewPost() {
    return Axios.get('/api/GetMostRecentWhatsNewPost').then(response => response.data).catch(error => console.log(error));
}

export function getActiveWhatsNewPosts() {
    return Axios.get('/api/GetActiveWhatsNewPosts').then(response => response.data).catch(error => console.log(error));
}

export function getWhatsNewPostByID(id) {
    return Axios.get('/api/GetWhatsNewPostByID', {
        params: {
            id
        }
    }).then(response => response.data).catch(error => console.log(error));
}