import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import '../../../css/card.css';
import {
    reportDefinitionTypes, reportComponentTypes, updateDefinitionType, getReportDefinitionMasterByDefinitionType, deleteDefinitionType,
    updateComponentType, getReportComponentsByComponentType, deleteComponentType, getAdminItems, insertDefinitionType, insertComponentType
} from '../../../common/services/ReportService';
import { takeWhile } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import { toast, confirm } from '@rickylandino/react-messages';

class ManageReportBuilder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            definitionTypes: [],
            componentTypes: [],
            formFields: {},
            showAddDefinitionType: false,
            showAddComponentType: false
        }

        this.alive = false;
    }

    componentDidMount() {
        this.alive = true;

        combineLatest(
            reportDefinitionTypes,
            reportComponentTypes
        ).pipe(takeWhile(() => this.alive)).subscribe(([rdt, rct]) => {
            if (rdt?.length > 0 && rct?.length > 0) {
                this.setState({
                    definitionTypes: rdt,
                    componentTypes: rct
                });
            }
        });
    }

    componentWillUnmount() {
        this.alive = false;
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    showAddDefinitionType = () => {
        this.setState({
            showAddDefinitionType: true
        });
    }

    cancelAddDefinitionType = () => {
        this.setState({
            showAddDefinitionType: false,
            formFields: { ...this.state.formFields, newDefinitionName: '' }
        });
    }

    addDefinitionType = () => {
        insertDefinitionType(this.state.formFields.newDefinitionTypeName).then(data => {

            toast.success("Definition type has been added.");

            this.setState({
                showAddDefinitionType: false,
                newDefinitionTypeName: ''
            }, () => getAdminItems());
        }).catch(error => {
            toast.error("Something went wrong.")
        });
    }

    toggleEditDefinitionType = (idx) => {
        let definitionTypes = this.state.definitionTypes.map((dt, i) => i !== idx ? ({ ...dt, tempEdit: false }) : ({ ...dt }));

        let type = definitionTypes[idx];
        type.tempEdit = type.tempEdit ? false : true;

        definitionTypes[idx] = type;

        this.setState({
            definitionTypes,
            formFields: { ...this.state.formFields, existingDefinitionType: type.definitionTypeName }
        });
    }

    updateDefinitionType = (idx) => {

        let definitionTypes = this.state.definitionTypes;
        let definitionType = this.state.definitionTypes[idx];

        definitionType.definitionTypeName = this.state.formFields.existingDefinitionType;

        updateDefinitionType(definitionType).then(data => {

            toast.success("Definition type has been added.");

            definitionType.tempEdit = false;

            definitionTypes[idx] = definitionType;

            this.setState({
                definitionTypes
            });

        }).catch(error => {
            toast.error("Something went wrong.")
        });
    }

    deleteDefinitionType = (idx) => {
        let definitionTypes = this.state.definitionTypes;
        let definitionType = this.state.definitionTypes[idx];

        getReportDefinitionMasterByDefinitionType(definitionType.reportDefinitionTypesID).then(data => {
            if (data.length > 0) {
                toast.error("Please reassign all definitions under this type before deleting.");
            } else {
                confirm({
                    title: "You are about to delete this definition type.",
                    content: "Are you sure you would like to proceed?",
                    buttons: ["Yes", "No"],
                    theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                }, (buttonPressed) => {
                    if (buttonPressed === 'Yes') {
                        deleteDefinitionType(definitionType).then(data => {

                            toast.success("Definition type has been deleted.");

                            definitionTypes.splice(idx, 1);

                            this.setState({
                                definitionTypes
                            });

                        }).catch(error => {
                            toast.error("Something went wrong.")
                        });

                        return 0;
                    } else {
                        return 0;
                    }
                });
            }
        });
    }

    showAddComponentType = () => {
        this.setState({
            showAddComponentType: true
        });
    }

    cancelAddComponentType = () => {
        this.setState({
            showAddComponentType: false,
            formFields: { ...this.state.formFields, newComponentTypeName: '' }
        });
    }

    addComponentType = () => {
        insertComponentType(this.state.formFields.newComponentTypeName).then(data => {

            toast.success("Component type has been added.");

            this.setState({
                showAddComponentType: false,
                newComponentTypeName: ''
            }, () => getAdminItems());
        }).catch(error => {
            toast.error("Something went wrong.")
        });
    }

    toggleEditComponentType = (idx) => {
        let componentTypes = this.state.componentTypes.map((ct, i) => i !== idx ? ({ ...ct, tempEdit: false }) : ({ ...ct }));

        let type = componentTypes[idx];
        type.tempEdit = type.tempEdit ? false : true;

        componentTypes[idx] = type;

        this.setState({
            componentTypes,
            formFields: { ...this.state.formFields, existingComponentType: type.componentTypeName }
        });
    }

    updateComponentType = (idx) => {

        let componentTypes = this.state.componentTypes;
        let componentType = this.state.componentTypes[idx];

        componentType.componentTypeName = this.state.formFields.existingComponentType;

        updateComponentType(componentType).then(data => {

            toast.success("Component type has been added.");

            componentType.tempEdit = false;

            componentTypes[idx] = componentType;

            this.setState({
                componentTypes
            });

        }).catch(error => {
            toast.error("Something went wrong.")
        });
    }

    deleteComponentType = (idx) => {
        let componentTypes = this.state.componentTypes;
        let componentType = this.state.componentTypes[idx];

        getReportComponentsByComponentType(componentType.reportComponentTypesID).then(data => {
            if (data.length > 0) {

                toast.error("Please reassign all components under this type before deleting.")
            } else {

                confirm({
                    title: "You are about to delete this component type.",
                    content: "Are you sure you would like to proceed?",
                    buttons: ["Yes", "No"],
                    theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                }, (buttonPressed) => {
                    if (buttonPressed === 'Yes') {

                        deleteComponentType(componentType).then(data => {

                            toast.success("Component type has been deleted.");

                            componentTypes.splice(idx, 1);

                            this.setState({
                                componentTypes
                            });

                        }).catch(error => {
                            toast.error("Something went wrong.")
                        });

                        return 0;
                    } else {
                        return 0;
                    }
                });

                
            }
        });
    }

    render() {
        return (
            <div className="row">
                <div className="col col-lg-6 col-12">
                    <Card className="pinkCard">
                        <Card.Header>
                            <span>Manage Definition Types <i className="far fa-plus-square fa-15x hover float-right" onClick={this.showAddDefinitionType}/></span>
                        </Card.Header>
                        <Card.Body className="pt-3">
                            {this.state.definitionTypes.map((type, idx) =>
                                <div className="row" key={idx}>
                                    <div className="col-lg-12 pl-5 pr-5">
                                        {type.tempEdit ?
                                            <div>
                                                <div className="form-group col-12">
                                                    <label className="form-label"></label>
                                                    <input className="form-control-custom" name="existingDefinitionType" value={this.state.formFields.existingDefinitionType || ''} onChange={this.handleInputChange} />
                                                </div>
                                                <div className="form-group col-12">
                                                    <button className="btn btn-submit" onClick={() => this.updateDefinitionType(idx)}>Save</button>
                                                    <button className="btn btn-outline" onClick={() => this.toggleEditDefinitionType(idx)}>Cancel</button>
                                                </div>
                                            </div>
                                            :
                                            <span className="font-size-15">
                                                <em>{type.definitionTypeName}</em>
                                            </span>
                                            }

                                        {type.definitionTypeName !== 'Misc.' ? <i className="far fa-trash-alt float-right ml-3 hover text-danger" onClick={() => this.deleteDefinitionType(idx)} /> : <i title="This type cannot be deleted" className="far fa-ban float-right ml-3 text-transparent"></i>}
                                        <i className="far fa-edit float-right hover" onClick={() => this.toggleEditDefinitionType(idx)} />
                                    </div>
                                    <div className="horizontal-divider" />
                                </div>
                            )}

                            {this.state.showAddDefinitionType &&
                                <div className="row">
                                    <div className="col-lg-12 pl-5 pr-5">
                                        <div>
                                            <div className="form-group col-12">
                                            <label className="form-label">New Definition Type</label>
                                            <input className="form-control-custom" name="newDefinitionTypeName" value={this.state.formFields.newDefinitionTypeName || ''} onChange={this.handleInputChange} />
                                            </div>
                                        <div className="form-group col-12">
                                            <button className="btn btn-submit" onClick={this.addDefinitionType}>Save</button>
                                            <button className="btn btn-outline" onClick={this.cancelAddDefinitionType}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="horizontal-divider" />
                                </div>
                            }
                        </Card.Body>
                    </Card>
                </div>

                <div className="col col-lg-6 col-12">
                    <Card className="pinkCard">
                        <Card.Header>
                            <span>Manage Component Types <i className="far fa-plus-square fa-15x hover float-right" onClick={this.showAddComponentType} /></span>
                        </Card.Header>
                        <Card.Body className="pt-3">
                            {this.state.componentTypes.map((type, idx) =>
                                <div className="row" key={idx}>
                                    <div className="col-lg-12 pl-5 pr-5">
                                        {type.tempEdit ?
                                            <div>
                                                <div className="form-group col-12">
                                                    <label className="form-label"></label>
                                                    <input className="form-control-custom" name="existingComponentType" value={this.state.formFields.existingComponentType || ''} onChange={this.handleInputChange} />
                                                </div>
                                                <div className="form-group col-12">
                                                    <button className="btn btn-submit" onClick={() => this.updateComponentType(idx)}>Save</button>
                                                    <button className="btn btn-outline" onClick={() => this.toggleEditComponentType(idx)}>Cancel</button>
                                                </div>
                                            </div>
                                            :
                                            <span className="font-size-15">
                                                <em>{type.componentTypeName}</em>
                                            </span>
                                        }

                                        {type.componentTypeName !== 'Misc.' ? <i className="far fa-trash-alt float-right ml-3 hover text-danger" onClick={() => this.deleteComponentType(idx)} /> : <i title="This type cannot be deleted" className="far fa-ban float-right ml-3 text-transparent"></i>}
                                        <i className="far fa-edit float-right hover" onClick={() => this.toggleEditComponentType(idx)} />
                                    </div>
                                    <div className="horizontal-divider" />
                                </div>
                            )}
                            {this.state.showAddComponentType &&
                                <div className="row">
                                    <div className="col-lg-12 pl-5 pr-5">
                                        <div>
                                            <div className="form-group col-12">
                                                <label className="form-label">New Component Type</label>
                                            <input className="form-control-custom" name="newComponentTypeName" value={this.state.formFields.newComponentTypeName || ''} onChange={this.handleInputChange} />
                                            </div>
                                        <div className="form-group col-12">
                                            <button className="btn btn-submit" onClick={this.addComponentType}>Save</button>
                                            <button className="btn btn-outline" onClick={this.cancelAddComponentType}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="horizontal-divider" />
                                </div>
                            }

                        </Card.Body>
                    </Card>
                </div>
            </div>
        );
    }
}

export default withRouter(ManageReportBuilder);