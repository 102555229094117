import React from "react";
import { withRouter } from "react-router-dom";
import cookie from 'react-cookies';
import Axios from '../../config/axios';
import QRCode from 'qrcode.react';
import { toast } from '@rickylandino/react-messages';

class TFASetup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            keyuri: '',
            code: '',
            formFields: {
                tfaInput: '',
                trustdevice: false
            },
        };
    }

    componentDidMount() {
        let postdata = {};
        postdata.userid = this.props.userid.toString();
        //postdata.password = this.props.password.toString();

        Axios.post(`/api/GetOtpAuth`, postdata
        ).then(response => {
            const keyuri = response.data.keyuri;
            const code = response.data.code;
            this.setState({ keyuri, code });

        }).catch(error => {
            console.log(error);
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    verify2FA = async (event) => {
        event.preventDefault();
        let validCode = false;

        let postdata = {};
        postdata.userid = this.props.userid.toString();
        //postdata.password = this.props.password.toString();
        postdata.totpcode = this.state.formFields.tfaInput;

        await Axios.post(`/api/Verify2FA`, postdata
        ).then(response => {
            validCode = response.data;
        }).catch(error => {
            console.log(error);
        });

        if (validCode) {
            const timeInSeconds = 3600;
            const expires = new Date();
            expires.setDate(Date.now() + timeInSeconds * 60 * 60 * 24 * 14);
            if (this.state.formFields.trustdevice === true) {
                cookie.save('bypass2FA' + this.props.userid, true, { path: '/', expires, maxAge: timeInSeconds });
            }

            let postdata = {};
            postdata.userid = this.props.userid.toString();
            //postdata.password = this.props.password.toString();

            Axios.post('/api/SetTFAEnabled', postdata
            ).then(response => {
                //const results = response.data;
            }).catch(error => {
                console.log(error);
            });

            //this.props.validateUser();

            let path = '/worklist';
            this.props.history.push(path);
        }
        else {
            toast.error("Invalid 2FA Code.");
        }
    }

    goLogin = () => {
        this.props.goLogin();
    }

    render() {

        return (
            <div>
            <div className="slidePaneContent height100">
                
                    <form id="login-form" className="" autoComplete="off">
                        <header>
                            Enter Code From Your Mobile Device
                    </header>
                        <fieldset className="padding-25-10">
                        <div className="">
                            <p>To use an authenticator app, go through the following steps: </p><br />
                            <ol>
                                <li>Download a two-factor authenticator app like Microsoft Authenticator for Windows Phone, Android
                                    and iOS or Google Authenticator for Android and iOS.
                            </li>
                            <li>
                                Scan the QR Code into your two factor authenticator app
                                <div className="text-center margin-10-0">
                                    <QRCode value={this.state.keyuri} />
                                    <div>OR</div>
                                </div>
                                
                                <div>Enter the human readable code provided below:</div>
                                <strong>{this.state.code}</strong>
                                
                            </li>
                                <li>Once you have scanned the QR Code above, your two factor authenticator app will provide you with a
                                    unique code. Enter the code in the confirmation box below.
                                
                                </li>
                            </ol>
                            <input type="hidden" value="" />

                            <div className="">
                            <label className="form-label">Enter the security code from the authenticator app on your mobile device</label>
                            <input autoFocus className="form-control" name="tfaInput" onChange={this.handleInputChange} value={this.state.formFields.tfaInput} />
                            <span className="help-block">
                                6-digit code
                            </span>
                        </div>
                        </div>
                    </fieldset>
                        <div className="form-group text-left">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" name="trustdevice" id="trustdevice" value={this.state.formFields.trustdevice} onChange={this.handleInputChange} />
                                <label className="custom-control-label" htmlFor="rememberme"> Trust this device. Security code will not be requested any more on this device.</label>
                            </div>
                        </div>
                    <div className="row">
                        <div className="form-group col-12 text-right">
                            <button className="btn btn-default" onClick={(e) => { this.verify2FA(e); }}>Secure login</button>
                        </div>
                        <div className="form-group col-12 goBackLogin text-right">
                            <span className="a hover" onClick={this.goLogin}><i className="fa fa-arrow-left"></i> Go back to login</span>
                        </div>
                        <br />
                        {this.state.validUserFound === false &&
                            <div>
                                <span style={{ color: 'red' }}><h5>Invalid User Credentials</h5></span>
                            </div>
                        }
                    </div>
                </form>
                </div>
                </div>
        );
    }
}
export default withRouter(TFASetup);