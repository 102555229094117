import React, { Component } from 'react';
import { Route, withRouter, Redirect, Switch } from 'react-router';
import { Layout } from './components/Layout';
import Login from './components/Auth/Login';
import Providers from './components/Maintenance/Providers';
import Globals from '../src/config/globals';
import PatientsHome from './components/Patient/PatientsHome';
import PatientDashboard from './components/Patient/PatientDashboard';

import Modal from 'react-modal';
import ExamSearch from './components/Exams/ExamSearch';
import RecallHome from './components/Recall/RecallHome';
import AdminHome from './components/Admin/AdminHome';
import WorklistHome from './components/Worklist/WorklistHome';

import Templates from './components/Report/Templates/ReportTemplates';
import Components from './components/Report/Components/ReportComponents';
import Definitions from './components/Report/Definitions/ReportDefinitions';
import Library from './components/Report/Library/ReportLibrary';
import DefinitionHome from './components/Report/Definition/DefinitionHome';
import { userAssociatedFacilities, getAllTypes, getVersion } from './common/services/ApiService';
import { insertNewVersion } from './common/services/CachingService';
import DailyExamDashboard from './components/DailyExams/DailyExamDashboard';
import { activeRoute } from './common/services/SpaService';
import ResetPassword from './components/Auth/ResetPassword';
import DetectActivity from './components/Helpers/DetectActivity';
import Insurance from './components/Maintenance/Insurance';

import { Modal as AntModal } from 'antd';
import WhatsNew from './components/WhatsNew/WhatsNew';

import { toast } from '@rickylandino/react-messages';
import ViewAuditHistory from './components/Admin/View/ViewAuditHistory';
import { getLoadInfo } from './common/services/UsersService';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedFacility: {},
            theme: window.sessionStorage.getItem("theme") || "light",
            version: '',
            refreshCache: false
        }

        if (window.sessionStorage.getItem("userInfo")) {
            Globals.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
            getLoadInfo(Globals.userInfo.userId).then(data => {
                userAssociatedFacilities.next(data.userAssociatedFacilities);
                Globals.associatedFacilities = data.userAssociatedFacilities;
            })
            getAllTypes();
        }

        if (window.sessionStorage.getItem("defaultFacility")) {
            Globals.defaultFacility = JSON.parse(window.sessionStorage.getItem("defaultFacility"));
        }
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            activeRoute.next(location.pathname);
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    componentDidMount() {
        activeRoute.next(this.props.history.location.pathname);
        Modal.setAppElement('#root');

        this.getVersionInformation();

        AntModal.destroyAll();
    }

    getVersionInformation = async () => {
        let basePath = window.location.href;
        var baseUrl = basePath.replace(/\/+$/, '') + '/' + 'meta.json';

        const response = await fetch(baseUrl, {
            cache: 'no-store'
        });

        const meta = await response.json();
        
        getVersion().then(data => {
            if (`${meta.version}` !== data.cacheVersion) {
                toast.loading("Update in progress");
                this.setState({
                    refreshCache: true
                });

                insertNewVersion(meta.version).then(data => {
                        this.props.emptyCache();
                });
            } else if (window.localStorage.APP_VERSION !== data.cacheVersion && !this.props.isLatestVersion) {
                toast.loading("Update in progress");
                this.setState({
                    refreshCache: true
                });

                this.props.emptyCache();
            }

            let versionToUse = data.webVersion?.split('+')[0];

            this.setState({
                version: versionToUse
            });
        });
    }

    changeFacility = () => {
        this.setState({
            selectedFacility: Globals.selectedFacility
        });
    }

    updatePatientsLoading = () => {
        this.setState({
            loadingPatients: Globals.loadingPatients
        });
    }

    updatePatients = () => {
        this.setState({
            patientsLoaded: true,
            loadingPatients: false
        });
        Globals.loadingPatients = false;
    }

    toggleDarkMode = (theme) => {

        this.setState({
            theme
        });

        Globals.theme = theme;
    }

    render() {

        return (
            <div className="nav-function-top" data-theme={this.state.theme}>
                {
                    (this.props.location.pathname === '/' || this.props.location.pathname === '/reset-password') ? (
                        <div>
                            <Route exact path='/' render={(props) => <Login toggleDarkMode={this.toggleDarkMode} />} />
                            <Route exact path='/reset-password' component={ResetPassword} />
                        </div>
                    ) : window.sessionStorage.getItem("userInfo") ? (

                            <DetectActivity>
                            <Layout changeFacility={this.changeFacility} updatePatientsLoading={this.updatePatientsLoading} updatePatients={this.updatePatients} toggleDarkMode={this.toggleDarkMode}>
                                
                                <Switch>
                                    <Route path='/patients' render={(props) => <PatientsHome selectedFacility={this.state.selectedFacility} loadingPatients={this.state.loadingPatients} patientsLoaded={this.state.patientsLoaded} />} />
                                    <Route exact path='/patient-dashboard' render={(props) => (this.props.location.state ? <PatientDashboard updatePatients={this.updatePatients} /> : <Redirect to='/patients' />)} />
                                    <Route exact path='/admin-panel' render={(props) => (JSON.parse(window.sessionStorage.getItem("userInfo")).isAdmin ? <AdminHome /> : <Redirect to='/patients' />)} />
                                    <Route exact path='/report-definitions' render={(props) => (JSON.parse(window.sessionStorage.getItem("userInfo")).isAdmin ? <DefinitionHome /> : <Redirect to='/patients' />)} />
                                    <Route exact path='/providers' render={(props) => (JSON.parse(window.sessionStorage.getItem("userInfo")).isAdmin ? <Providers /> : <Redirect to='/patients' />)} />
                                        <Route exact path='/audit-history' render={(props) => ((JSON.parse(window.sessionStorage.getItem("userInfo")).isAdmin || JSON.parse(window.sessionStorage.getItem("userInfo")).managerYN) ? <ViewAuditHistory /> : <Redirect to='/patients' />)} />
                                </Switch>

                                <Route path='/recall' component={RecallHome} />
                                <Route path='/insurance-maintenance' component={Insurance} />
                                <Route path='/exam-search' component={ExamSearch} />
                                <Route path='/worklist' render={(props) => <WorklistHome selectedFacility={this.state.selectedFacility} />} />
                                <Route path='/reporttemplates' component={Templates} />
                                <Route path='/reportcomponents' component={Components} />
                                <Route path='/reportdefinitions' component={Definitions} />
                                <Route path='/reportlibrary' component={Library} />
                                <Route path='/daily-exams' component={DailyExamDashboard} />
                                <Route path="/what's-new" component={WhatsNew} />

                                
                                
                                
                                </Layout>
                            </DetectActivity>)
                            :
                            <Redirect to='/' />
                }

                {(this.props.location.pathname === '/' || this.props.location.pathname === '/reset-password') ? 
                    <div style={{ position: 'fixed', bottom: '0', left: '10px' }}><span className="hidden-md-down fw-500"><i className="far fa-copyright"></i> {new Date().getFullYear()} <span className="fw-700">ONsite Women's Health.</span> All Rights Reserved</span> &nbsp; v{this.state.version}</div>
                    :
                    <footer className="page-footer" role="contentinfo">
                        <div className="d-flex align-items-center flex-1 text-muted">

                            <span className="hidden-md-down fw-500"><i className="far fa-copyright"></i> {new Date().getFullYear()} <span className="fw-700">ONsite Women's Health.</span> All Rights Reserved</span> &nbsp; v{this.state.version}
                        </div>
                    </footer>
                }

                {this.state.refreshCache && <div className="refresh-overlay" />}
            </div>
        );
    }
}

export default withRouter(App);