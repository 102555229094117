import Axios from '../../config/axios';

export function insertNewVersion(content) {

    let postdata = {
        content
    }

    return Axios.post(`/api/InsertNewVersion`, postdata
    ).then(response =>
        response.data
    ).catch(error => {
        console.log(error);
    });
}