import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Axios from '../../config/axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import statesList from '../Helpers/StatesList';

import Card from 'react-bootstrap/Card';
import '../../css/card.css';

import { Select } from 'antd';

import { toast } from '@rickylandino/react-messages';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import MaskedInput from 'react-text-mask'

class PatientTab extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.updatePatient) {
            return {
                patient: nextProps.patient,
                facility: nextProps.facility,
                formFields: nextProps.patient,
                persistentFormFields: nextProps.patient,
                selectedEthnicity: nextProps.patientEthnicity
            }
        }
        return null;
    }

    constructor(props) {
        super(props);

        this.state = {
            patient: props.location.state.selectedObject.patientModel,
            insurance: props.location.state.selectedObject.insuranceModel,
            facility: props.location.state.selectedObject.facilityModel,
            formFields: props.location.state.selectedObject.patientModel,
            persistentFormFields: props.location.state.selectedObject.patientModel,
            insuranceCompanies: [],
            primaryCarePhysicians: [],
            priorStatusList: [],
            providersList: [],
            states: statesList.filter(obj => { return obj.country_code === 'US' }),
            patientAge: 0,
            selectedEthnicity: props.patientEthnicity
        };        
    }

    componentDidMount() {
        this.getProviders();
        this.setFormFields();
        this.getInsuranceCompanies();
        this.getAllPriorStatus();
        this.getAllPrimaryCarePhysicians();
    }

    componentDidUpdate() {
        if (this.props.updatePatient) {
            this.getProviders();
            this.setFormFields();
            this.getInsuranceCompanies();
            this.getAllPriorStatus();
            this.getAllPrimaryCarePhysicians();
        }
    }

    setFormFields = () => {

        let formFields = this.state.formFields;
        formFields.insCompName = this.state.insurance && this.state.insurance.insCompName;
        //formFields.nextApptDate = formFields.nextApptDate && dateWithNoTimezone(formFields.nextApptDate);

        this.setState({
            formFields
        }, () => {
                this.calculateAge();
        });
    }

    onCalendarChange = (date, name) => {
        this.setState({
            [name]: date
        });
    }

    handleDateChange = (name, date) => {
        this.setState({ formFields: { ...this.state.formFields, [name]: date } }, () => {
            this.calculateAge();
            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                this.isDirty = true;  // dirty
            }
        });
    }

    calculateAge = () => {
        var today = new Date();
        var birthDate = new Date(this.state.formFields.dob);  // create a date object directly from `dob1` argument
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }

        this.setState({
            patientAge: age_now
        });
    }

    clearChanges = () => {
        let formFields = this.state.persistentFormFields;

        this.setState({
            formFields
        });

        this.isDirty = false;
    }

    getProviders = () => {
        Axios.get(`/api/GetProvidersByFacility`, {
            params: {
                facilityID: this.state.facility.facilityID
            }}
        ).then(response => {
            let providersList = response.data;
            for (var i = 0; i < providersList.length; i++) {
                providersList[i].name = providersList[i].fName + ' ' + providersList[i].lName
            }

            this.setState({
                providersList
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getAllPriorStatus = () => {
        Axios.get(`/api/GetAllPriorStatus`
        ).then(response => {
            this.setState({
                priorStatusList: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getAllPrimaryCarePhysicians = () => {
        Axios.get(`/api/GetPCPByFacility`, {
            params: {
                facilityID: this.state.facility.facilityID
            }
        }).then(response => {
            this.setState({
                primaryCarePhysicians: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getInsuranceCompanies = () => {
        Axios.get(`/api/GetInsuranceCompaniesByFacility`, {
            params: {
                facilityID: this.state.facility.facilityID
            }
        }).then(response => {
            this.setState({
                insuranceCompanies: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    handleSubmit = () => {
        const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
        let patient = this.state.formFields;

        console.log(this.state.selectedEthnicity);

        if (!patient.lName || !patient.fName || !patient.mrn || !patient.dob ||
            !patient.facilityID || !patient.address1 || !patient.city || !patient.state || !patient.zip || !patient.providerID || !patient.insuranceID || !this.state.selectedEthnicity?.length > 0) {
            toast.error("Please fill in all required data");
        } else {
            let postdata = {};
            postdata.patient = this.state.formFields;
            postdata.patient.providerID = postdata.patient.providerID === '' ? null : postdata.patient.providerID;
            postdata.patient.insuranceID = postdata.patient.insuranceID === '' ? null : postdata.patient.insuranceID;
            postdata.patient.primaryCarePhysicianID = postdata.patient.primaryCarePhysicianID === '' ? null : postdata.patient.primaryCarePhysicianID;

            postdata.ethnicBackground = this.state.selectedEthnicity;

            postdata.userID = userInfo.userId;

            Axios.post(`/api/UpdatePatient`, postdata
            ).then(response => {
                if (response.data) {
                    toast.success("Patient has been updated");

                    this.props.updatePatientInfo(this.state.selectedEthnicity);
                } else {
                    toast.error("MRN is not unique. Please try with a unique MRN.");
                }

            }).catch(error => {
                toast.error("Something went wrong");
            });
        }
    }

    handleEthnicBackgroundChange = (selectedEthnicity) => {
        
        this.setState({
            selectedEthnicity
        });
    }

    render() {
        const { Option } = Select;

        return (
            <div>

                <Card className="pinkCard">
                    <Card.Header><span>General Information</span>
                    </Card.Header>
                    <Card.Body>

                        <form>
                            <div className="row">
                                <div className="form-group col-lg-3 col-12">
                                    <label className="form-label">Last Name <span className="color-pink">*</span></label>
                                    <input type="text" name="lName" value={this.state.formFields.lName || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-3 col-12">
                                    <label className="form-label">First Name <span className="color-pink">*</span></label>
                                    <input type="text" name="fName" value={this.state.formFields.fName || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-2 col-12">
                                    <label className="form-label not-required">Middle Name</label>
                                    <input type="text" name="mName" value={this.state.formFields.mName || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>

                                <div className="form-group col-lg-1 col-12">
                                    <label className="form-label">DOB <span className="color-pink">*</span></label>
                                    <DatePicker
                                        selected={this.state.formFields.dob ? Moment(this.state.formFields.dob).utc().toDate() : null}
                                        onChange={(date) => this.handleDateChange('dob', date)}
                                        className="form-control-custom"
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        customInput={
                                            <NumberFormat format="##/##/####" mask="_" />
                                        }
                                    />
                                </div>

                                <div className="form-group col-lg-1 col-12">
                                    <label className="form-label not-required">Age</label>
                                    <p>{this.state.patientAge > 0 && this.state.patientAge}</p>
                                </div>

                                <div className="form-group col-lg-2 col-12">
                                    <label className="form-label">Patient ID <span className="color-pink">*</span></label>
                                    <input type="text" name="mrn" value={this.state.formFields.mrn || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>


                                <div className="form-group col-lg-3 col-12">
                                    <label className="form-label">Address 1 <span className="color-pink">*</span></label>
                                    <input type="text" name="address1" value={this.state.formFields.address1 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-3 col-12">
                                    <label className="form-label not-required">Address 2</label>
                                    <input type="text" name="address2" value={this.state.formFields.address2 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>

                                <div className="form-group col-lg-2 col-12">
                                    <label className="form-label">City <span className="color-pink">*</span></label>
                                    <input type="text" name="city" value={this.state.formFields.city || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>

                                <div className="form-group col-lg-2 col-12">
                                    <label className="form-label">State <span className="color-pink">*</span></label>
                                    <select className="form-control-custom" value={this.state.formFields.state} name="state" onChange={this.handleInputChange}>
                                        <option></option>
                                        {this.state.states.map((state, idx) => <option key={idx} value={state.state_code}>{state.name}</option>)}
                                    </select>
                                </div>

                                <div className="form-group col-lg-2 col-12">
                                    <label className="form-label">Zip <span className="color-pink">*</span></label>
                                    <input type="text" name="zip" value={this.state.formFields.zip || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>

                                <div className="form-group col-lg-2">
                                    <label className="form-label not-required">Cell Phone</label>
                                    <NumberFormat className="form-control-custom" name="cellPhone" value={this.state.formFields.cellPhone || ''} onChange={this.handleInputChange} format="(###) ###-####" mask="_" />
                                </div>

                                <div className="col-lg-2 col-12">
                                    <label className="form-label not-required">Patient consents to receive text alerts</label>
                                    <div className="form-control-custom no-border">
                                        <div className="custom-control custom-checkbox custom-control-inline">
                                            <input type="checkbox" className="custom-control-input" id="" name="consentToTexts" onChange={this.handleInputChange} defaultChecked={this.state.formFields.consentToTexts} />
                                            <label className="custom-control-label"></label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group col-lg-2">
                                    <label className="form-label not-required">Home Phone</label>
                                    <NumberFormat className="form-control-custom" name="homePhone" value={this.state.formFields.homePhone || ''} onChange={this.handleInputChange} format="(###) ###-####" mask="_" />
                                </div>

                                <div className="form-group col-lg-2">
                                    <label className="form-label not-required">Work Phone</label>
                                    <NumberFormat className="form-control-custom" name="workPhone" value={this.state.formFields.workPhone || ''} onChange={this.handleInputChange} format="(###) ###-####" mask="_" />
                                </div>

                                <div className="form-group col-lg-1">
                                    <label className="form-label not-required">Ext.</label>
                                    <input type="number" name="workPhoneExt" value={this.state.formFields.workPhoneExt || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>

                                <div className="form-group col-lg-3">
                                    <label className="form-label not-required">Email</label>
                                    <input type="text" name="email" value={this.state.formFields.email || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>

                                <div className="form-group col-lg-2">
                                    <label className="form-label">Insurance <span className="color-pink">*</span></label>
                                    <select className="form-control-custom" value={this.state.formFields.insuranceID || ''} name="insuranceID" onChange={this.handleInputChange}>
                                        <option value=''/>
                                        {this.state.insuranceCompanies.map((company, idx) => <option key={idx} value={company.insuranceID} onChange={this.handleInputChange}>{company.insCompName}</option>)}

                                    </select>
                                </div>

                                <div className="form-group col-lg-2">
                                    <label className="form-label">Referring MD <span className="color-pink">*</span></label>
                                    <select className="form-control-custom" value={this.state.formFields.providerID || ''} name="providerID" onChange={this.handleInputChange}>
                                        <option value=''/>
                                        {this.state.providersList.map((prov, idx) => <option key={idx} value={prov.providerID} onChange={this.handleInputChange}>{prov.name}</option>)}

                                    </select>
                                </div>

                                <div className="form-group col-lg-2">
                                    <label className="form-label not-required">Primary Care Physician</label>
                                    <select className="form-control-custom" value={this.state.formFields.primaryCarePhysicianID || ''} name="primaryCarePhysicianID" onChange={this.handleInputChange}>
                                        <option value='' />
                                        {this.state.primaryCarePhysicians.map((pcp, idx) => <option key={idx} value={pcp.primaryCarePhysicianID}>{pcp.fName} {pcp.lName}</option>)}
                                    </select>
                                </div>


                                <div className="form-group col-lg-2">
                                    <label className="form-label not-required">Next Appt Date</label>
                                    <DatePicker
                                        selected={this.state.formFields?.nextApptDate ? new Date(this.state.formFields.nextApptDate) : null}
                                        onChange={(date) => this.handleDateChange('nextApptDate', date)}
                                        className="form-control-custom"
                                        showTimeSelect
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        dateFormat="MM/dd/yyyy hh:mm aa"
                                        customInput={
                                            <MaskedInput
                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/, ' ', /(?:A|P|a|p)/, /(?:M|m)/]}
                                            />
                                        }
                                    />
                                </div>

                                <div className="form-group col-lg-2">
                                    <label className="form-label not-required">Prior Status</label>
                                    <select className="form-control-custom" value={this.state.formFields.priorStatusID || ''} name="priorStatusID" onChange={this.handleInputChange}>
                                        <option value={null} />
                                        {this.state.priorStatusList.map((status, idx) => <option key={idx} value={status.priorStatusID}>{status.priorStatus}</option>)}
                                    </select>
                                </div>

                                <div className="form-group col-lg-2">
                                    <label className="form-label">Racial or Ethnic Background <span className="color-pink">*</span></label>
                                    <Select
                                        mode="multiple"
                                        maxTagCount={1}
                                        allowClear
                                        placeholder="Please select"
                                        onChange={this.handleEthnicBackgroundChange}
                                        className="form-control-custom w-100"
                                        bordered={false}
                                        value={this.state.selectedEthnicity}
                                    >
                                        <Option value='AfricanAmericanBlack'>African American/Black</Option>
                                        <Option value='CaucausianWhite'>Caucasian/White</Option>
                                        <Option value='HispanicLatina'>Hispanic/Latina</Option>
                                        <Option value='AmericanIndianAlaskaNative'>American Indian/Alaska Native</Option>
                                        <Option value='Asian'>Asian</Option>
                                        <Option value='NativeHawaiianOtherPacificIslander'>Native Hawaiian/Other Pacific Islander</Option>
                                        <Option value='OtherRace'>Other</Option>
                                    </Select>
                                </div>

                                <div className="form-group col-lg-6">
                                    <label className="form-label not-required">Clinical</label>
                                    <textarea rows="3" name="clinicalNotes" value={this.state.formFields.clinicalNotes || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>

                                <div className="form-group col-lg-6">
                                    <label className="form-label not-required">Notes</label>
                                    <textarea rows="3" name="notes" value={this.state.formFields.notes || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                            </div>
                        </form>

                    </Card.Body>
                </Card>

                <button className="btn btn-submit" onClick={this.handleSubmit}>Save</button>
                <button className="btn btn-outline-default ml-3" onClick={this.clearChanges}>Clear</button>
            </div>
        );
    }
}

export default withRouter(PatientTab);