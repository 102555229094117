import React from 'react';

class Globals extends React.Component {

    static userInfo = {
        userId: "",
        username: "",
        fullName: "",
        isLoggedIn: false,
        bearer: "",
        isAdmin: false,
        passwordEncrypted: "",
        tfaEnabled: false
    };

    static selectedFacility = {};

    static isDirtyPage = false;

    static patients = [];
    static loadingPatients = false;
    static associatedFacilities = [];
    static defaultFacility = {};
    static searchCriteria = {};
    static patientSearchCriteria = '';

    static theme = "light";
}

export default Globals;
