import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getRecallInformation, recallPrintTypes, getPrintRecallSearchResults, groupedRecallPrintTypes, printTypes } from '../../common/services/RecallService';
import { getAllPatientExamInfoByPatient, getPatientInformationById, userAssociatedFacilities, getPatientInformationByIDNew } from '../../common/services/ApiService';
import { takeWhile } from 'rxjs/operators';

import { combineLatest } from 'rxjs';

import { Empty, Select, Spin, message, TreeSelect  } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { Fragment } from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationListStandalone, PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import Axios from '../../config/axios';
import Globals from '../../config/globals';
import Moment from 'moment';
import { validateResetToken } from '../../common/services/AuthService';

class RecallHome extends Component {

    constructor(props) {
        super(props);

        this.state = {
            facilities: [],
            recallPrintTypes: [],
            selectedFacility: '',
            loaded: false,
            selectedRecallPrintTypes: [],
            selectedRPT: [],
            searchResults: [],
            loadingResults: false,
            landed: true,
            selected: [],
            formFields: {
                showPreviousPrinted: false,
                showUpcoming: false
            },
            selectedPatient: {},
            groupedItems: [],
            nonModifiedGroupItems: {}
        }

        this.alive = false;
    }

    componentDidMount() {
        

        this.alive = true;

        userAssociatedFacilities.pipe(takeWhile(() => this.alive)).subscribe(response => {
            let facilities = response;
            if (facilities?.length > 0) {

                let selectedFacilityObj = facilities.find(f => f.defaultFacility === 1)?.facilityModel || facilities[0].facilityModel;
                let b3AutoRecall = selectedFacilityObj.b3AutoRecallYN;

                let selectedFacility = facilities.find(f => f.defaultFacility === 1)?.facilityModel?.facilityID || facilities[0].facilityModel.facilityID;

                getRecallInformation(selectedFacility);

                this.setState({
                    facilities: facilities.filter(item => item.facilityModel.inactiveYN !== 1),
                    selectedFacility,
                    b3AutoRecall
                });
            }            
        });

        combineLatest(
            recallPrintTypes,
            groupedRecallPrintTypes,
            printTypes
        ).pipe(takeWhile(() => this.alive)).subscribe(response => {
            if (response[0].length > 0) {
                this.setState({
                    recallPrintTypes: response[0],
                    loaded: true
                });
            }

            if (response[1]) {

                this.setState({
                    nonModifiedGroupItems: response[1],
                    printTypes: response[2]
                }, () => this.setGroupedItems());
                
                //let groupedItems = [];
                //let obj = response[1];

                //let idx = 0;

                //for (const key of Object.keys(obj)) {
                //    let printType = response[2].find(pt => pt.printTypeID == key)?.printType;

                //    let children = obj[key].map(rpt => ({ title: rpt.recallPrintType, key: `${rpt.recallPrintTypesID}`, value: `${rpt.recallPrintTypesID}` }));

                //    let itemToAdd = {
                //        title: printType,
                //        value: `all:${key}`,
                //        key: `all:${key}`,
                //        children
                //    }

                //    if (!groupedItems.find(i => i.value === `all:${itemToAdd.value}`)) {
                //        groupedItems.push(itemToAdd);
                //    }
                //    idx++;
                //}

                //this.setState({
                //    groupedItems,
                //    nonModifiedGroupItems: response[1]
                //});
            }
        });
    }

    componentWillUnmount() {
        this.alive = false;
    }

    handleFacilityChange = (selectedFacility) => {

        getRecallInformation(selectedFacility);

        let selectedFacilityObj = this.state.facilities.find(f => f.facilityModel.facilityID === selectedFacility);
        
        let b3AutoRecall = selectedFacilityObj.facilityModel.b3AutoRecallYN;

        this.setState({
            selectedFacility,
            selected: [],
            b3AutoRecall,
            selectedRPT: []
        }, () => this.setGroupedItems());
    }

    setGroupedItems = () => {
        let groupedItems = [];
        let obj = { ...this.state.nonModifiedGroupItems };

        let idx = 0;

        for (const key of Object.keys(obj)) {
            let printType = this.state.printTypes.find(pt => pt.printTypeID == key)?.printType;

            let filteredList = obj[key];
            if (this.state.b3AutoRecall === 0) {
                filteredList = obj[key].filter(rpt => (!rpt.recallPrintType.includes('Diagnostic Ultrasound') && !rpt.recallPrintType.includes('Diagnostic Mammogram')));
            }

            let children = filteredList.map(rpt => ({ title: rpt.recallPrintType, key: `${rpt.recallPrintTypesID}`, value: `${rpt.recallPrintTypesID}` }));

            let itemToAdd = {
                title: printType,
                value: `all:${key}`,
                key: `all:${key}`,
                children
            }

            if (!groupedItems.find(i => i.value === `all:${itemToAdd.value}`)) {
                groupedItems.push(itemToAdd);
            }
            idx++;
        }

        this.setState({
            groupedItems
        });
    }

    handleRecallPrintTypesChange = (selectedRecallPrintTypes) => {
        this.setState({
            selectedRecallPrintTypes,
            selected: []
        });
    }

    handleTreeChange = (selectedRPT) => {
        let selectedRecallPrintTypes = [];

        selectedRPT.forEach(id => {
            if (id.includes('all:')) {
                let key = id.substring(4);

                let allItems = this.state.nonModifiedGroupItems[key];

                if (this.state.b3AutoRecall === 0) {
                    allItems = allItems.filter(rpt => (!rpt.recallPrintType.includes('Diagnostic Ultrasound') && !rpt.recallPrintType.includes('Diagnostic Mammogram')));
                }

                allItems.forEach(item => {
                        selectedRecallPrintTypes.push(item.recallPrintTypesID);
                });
            } else {
                selectedRecallPrintTypes.push(id);
            }
        });

        this.setState({ selectedRecallPrintTypes, selectedRPT });
    };

    handleSubmit = () => {
        if (this.state.selectedRecallPrintTypes.length === 0) {
            message.error('Please fill select a facility and at least one recall print type');
        } else {
            this.setState({
                loadingResults: true,
                landed: false
            });
            var rpt = [];

            this.state.selectedRecallPrintTypes.forEach(r => {
                rpt.push(this.state.recallPrintTypes.find(t => t.recallPrintType.recallPrintTypesID === r));
            });

            getPrintRecallSearchResults(this.state.selectedFacility, rpt, this.state.formFields.showPreviousPrinted, this.state.formFields.showUpcoming, this.state.formFields.showInactivePatients).then(data => {

                this.setState({
                    searchResults: data ? data : [],
                    loadingResults: false,
                    selected: []
                });
            });
        }
    }

    handleBtnClick = () => {
        if (!this.state.selected.includes(2)) {
            this.setState(() => ({
                selected: [...this.state.selected, 2]
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter(x => x !== 2)
            }));
        }
    }

    handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            this.setState(() => ({
                selected: [...this.state.selected, row.patientID]
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter(x => x !== row.patientID)
            }));
        }
    }

    handleOnSelectAll = (isSelect, rows) => {
        const ids = this.state.searchResults.map(r => r.patientID);
        if (isSelect) {
            this.setState(() => ({
                selected: ids
            }));
        } else {
            this.setState(() => ({
                selected: []
            }));
        }
    }

    handleBulkPrint = () => {
        message.success(this.state.selected.length + ' report(s) have been printed');

        let patientRecalls = [];

        this.state.selected.forEach(r => {
            let item = this.state.searchResults.find(sr => sr.patientID === r);

            item.recallDate = item.recallDate ? new Date(item.recallDate) : null;
            item.dob = item.dob ? new Date(item.dob) : null;
            item.nextApptDate = item.nextApptDate ? new Date(item.nextApptDate) : null;
            patientRecalls.push(item);
        });

        let pdm = {
            patientRecalls
        };

        const q = Axios.defaults.baseURL + "api/RenderReportFromRecallSelection";

        var form = document.createElement("form");
        form.target = "_blank";
        form.method = "POST";
        form.action = q;
        form.style.display = "none";

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "patientRecallsString";
        input.value = JSON.stringify(patientRecalls);
        form.appendChild(input);

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "userid";
        input.value = Globals.userInfo.userId;
        form.appendChild(input);

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    selectPatient(patient, categoryList) {
        if (patient) {
            getPatientInformationByIDNew(patient.patientModel.patientID).then(data => {
               
                this.props.history.push({
                    pathname: '/patient-dashboard',
                    state: { selectedObject: data.patientInformation, examInfo: data.examInfo, filteredCategoryList: categoryList, patientEthnicity: data.patientEthnicity }
                });
            }); 

            //getAllPatientExamInfoByPatient(patient.patientModel.patientID).then(data => {
            //    this.props.history.push({
            //        pathname: '/patient-dashboard',
            //        state: { selectedObject: patient, examInfo: data }
            //    });
            //});
        }
    }

    handleRenderSearchResults = () => {
        const q = Axios.defaults.baseURL + "api/RenderRecallSearchResults";

        var form = document.createElement("form");
        form.target = "_blank";
        form.method = "POST";
        form.action = q;
        form.style.display = "none";

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "patientRecallsString";
        input.value = JSON.stringify(this.state.searchResults);
        form.appendChild(input);

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }

    render() {

        const { SHOW_PARENT } = TreeSelect;

        const { SearchBar } = Search;
        const { Option } = Select;

        function folderFormatter(cell, row) {
            return (
                <i id="openChart" className="far fa-folder-open fa-125x color-pink text-center"></i>
            );
        }

        function dateFormatter(cell, row) {
            return (
                <span>
                    {cell &&
                        Moment(new Date(cell)).utc().format('L')
                    }
                </span>
                );
                }

        const columns = [{
            dataField: 'mrn',
            text: 'MRN #',
            sort: true
        }, {
            dataField: 'lName',
                text: 'Last Name',
                sort: true
        }, {
            dataField: 'fName',
                text: 'First Name',
                sort: true
        }, {
            dataField: 'dob',
            text: 'DOB',
            formatter: cell => cell && Moment(new Date(cell)).utc().format('L'),
            sort: true
        }, {
            dataField: 'recallSentDate',
            text: 'Print Date',
            formatter: cell => cell && Moment(new Date(cell)).utc().format('L'),
            sort: true
        }, {
            dataField: 'recallType',
            text: 'Recall Type',
            sort: true
        }, {
            dataField: 'recallDate',
            text: 'Recall Date',
            formatter: cell => cell && Moment(new Date(cell)).utc().format('L'),
            sort: true,
        }, {
            dataField: 'nextApptDate',
            text: 'Next Appt Date',
            formatter: cell => cell && Moment(new Date(cell)).utc().format('L'),
            sort: true,
        }, {
            dataField: 'dum1',
            isDummyField: true,
            text: 'Open Chart',
            formatter: folderFormatter
        }];

        const sizePerPageOptionRenderer = ({
            text,
            page,
            onSizePerPageChange
        }) => (
            <li
                key={text}
                role="presentation"
                className="form-control-custom hover"
                tabIndex="-1"
                data-page={page}
                onMouseDown={(e) => {
                    e.preventDefault();
                    onSizePerPageChange(page);
                }}
                style={{ color: 'pink' }}
            >
                <span className="a hover"
                    tabIndex="-1"
                    role="menuitem"
                    data-page={page}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        onSizePerPageChange(page);
                    }}
                    style={{ color: 'rgba(243, 0, 121, 1)' }}
                >
                    {text}
                </span>
            </li>
        );

        const pagination = {
            custom: true,
            sizePerPage: 10,
            sizePerPageList: [{ text: "5", value: 5 }, { text: "10", value: 10 }, { text: "50", value: 50 }, { text: "100", value: 100 }], // A numeric array is also available. the purpose of above example is custom the text
            sizePerPageOptionRenderer,
            totalSize: this.state.searchResults?.length
        };

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            selected: this.state.selected,
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll
        };


        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                if (e.target.tagName === 'I' && e.target.id === 'openChart'/*e.target.tagName === 'TD' || e.target.tagName === 'TR'*/) {

                    getPatientInformationById(row.patientID).then(data => {
                        this.setState({
                            selectedPatient: data
                        }, () => this.selectPatient(this.state.selectedPatient, row.categoryList));
                    });
                }
            },
            onDoubleClick: (e, row, rowIndex) => {
                getPatientInformationById(row.patientID).then(data => {
                    this.setState({
                        selectedPatient: data
                    }, () => this.selectPatient(this.state.selectedPatient, row.categoryList));
                });
            }
        };

        function customMatchFunc({
            searchText,
            value,
        }) {
            if (typeof value !== 'undefined') {
                return value.startsWith(searchText);
            }
            return false;
        }

        const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

        const tProps = {
            treeData: this.state.groupedItems,
            value: this.state.selectedRPT,
            onChange: this.handleTreeChange,
            treeCheckable: true,
            showCheckedStrategy: SHOW_PARENT,
            placeholder: 'Please select',
            style: {
                width: '100%',
            },
        };

        return (
            <Fragment>
                {this.state.loaded &&
                    <Fragment>
                        <div className="row">
                            <div className="col-lg-12 mb-4" >
                                <div className="panel-hdr">
                                    <h2>
                                        Recall
                                </h2>
                                </div>

                            </div>
                    </div>

                    <div className="row ml-4 mb-4 mr-4">
                            <div className="col col-lg-6 col-12">
                                <label className="form-label">Facility</label>
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={this.handleFacilityChange}
                                className="form-control-custom w-100"
                                bordered={false}
                                value={this.state.selectedFacility}
                                showSearch
                                virtual={false}
                                filterOption={(input, option) => (option.children[0] + option.children[1]).toLowerCase().includes(input.toLowerCase())}
                                    >
                                    {this.state.facilities.map((f, idx) => <Option key={idx} value={f.facilityModel.facilityID}>{f.facilityModel.facilityNumber && f.facilityModel.facilityNumber + " - "}{f.facilityModel.facilityName}</Option>)}
                                </Select>

                            </div>
                        </div>
                    <div className="row ml-4 mb-4 mr-4">
                        {/*    <div className="col col-lg-6 col-12">*/}
                        {/*        <label className="form-label">Recall Print Type</label>*/}
                        {/*        <Select*/}
                        {/*            mode="multiple"*/}
                        {/*            allowClear*/}
                        {/*            placeholder="Please select"*/}
                        {/*            onChange={this.handleRecallPrintTypesChange}*/}
                        {/*            className="form-control-custom w-100"*/}
                        {/*            bordered={false}*/}
                        {/*            maxTagCount={1}*/}
                        {/*            value={this.state.selectedRecallPrintTypes}*/}
                        {/*        >*/}
                        {/*        {this.state.recallPrintTypes.map((rpt, idx) => <Option key={rpt.recallPrintType.recallPrintType} value={rpt.recallPrintType.recallPrintType}>{rpt.recallPrintType.recallPrintType}</Option>)}*/}
                        {/*        </Select>*/}
                        {/*</div>*/}

                        <div className="col col-lg-6 col-12">
                            <label className="form-label">Recall Print Type</label>
                            <TreeSelect {...tProps}
                                className="form-control-custom w-100"
                                bordered={false}
                                maxTagCount={1} />
                        </div>

                        <div className="col-lg-2 col-12">
                            <label className="form-label">Show Inactive Patients</label>
                            <div className="form-control-custom no-border">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" id="showInactivePatients" name="showInactivePatients" value={this.state.formFields.showInactivePatients || ''} onChange={this.handleInputChange} />
                                    <label className="custom-control-label"></label>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 col-12">
                            <label className="form-label">Show previously printed letters</label>
                            <div className="form-control-custom no-border">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" id="" name="showPreviousPrinted" checked={this.state.formFields.showPreviousPrinted || false} onChange={this.handleInputChange} />
                                    <label className="custom-control-label"></label>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-12">
                            <label className="form-label">Show Patients With Upcoming Appointments</label>
                            <div className="form-control-custom no-border">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" id="" name="showUpcoming" checked={this.state.formFields.showUpcoming || false} onChange={this.handleInputChange} />
                                    <label className="custom-control-label"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ml-4 mr-4">
                        <div className="col-lg-6 col-12">
                            <button className="btn btn-outline-default color-pink" onClick={this.handleSubmit}>Search</button>
                        </div>
                        
                            <div className="col-lg-6 col-12 text-right">
                            {this.state.searchResults.length > 0 && <button className="btn btn-submit mr-3" onClick={this.handleRenderSearchResults}>Print Recall Search Results</button>}
                            {this.state.selected?.length > 0 && <button className="btn btn-submit" onClick={this.handleBulkPrint}>Print {this.state.selected.length} Letter(s)</button>}
                            </div>
                        
                    </div>
                    {this.state.landed ?
                        <div><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<p>Begin by searching on facility and print type</p>} /></div>
                        :
                        <div className="frame-wrap">
                            {this.state.loadingResults ?
                                <div className="frame-heading"><Spin indicator={antIcon} /> Loading...</div>
                                :
                                <Fragment>
                                    {this.state.searchResults?.length > 0 ?
                                        <Fragment>
                                            <PaginationProvider
                                                pagination={paginationFactory(pagination)}
                                            >
                                                {
                                                    ({
                                                        paginationProps,
                                                        paginationTableProps
                                                    }) => (
                                                        <div>
                                        <ToolkitProvider
                                                                keyField='patientID'
                                                                data={this.state.searchResults}
                                                                columns={columns}
                                                                columnToggle
                                                                search={{ customMatchFunc, searchFormatted: true }}
                                        >
                                            {
                                                props => (
                                                    <div>
                                                        <hr />
                                                        <SearchBar {...props.searchProps} />
                                                        <hr />
                                                        <BootstrapTable
                                                            pagination={paginationFactory(pagination)}
                                                            {...paginationTableProps}
                                                            {...props.baseProps}
                                                            rowEvents={rowEvents}
                                                            selectRow={selectRow}
                                                            hover condensed
                                                            filter={filterFactory()}                                                        />
                                                    </div>
                                                )
                                            }
                                            </ToolkitProvider>
                                                            <div className="custom-pagination">
                                                                <SizePerPageDropdownStandalone
                                                                    className="float-left mr-3"
                                                                    {...paginationProps}
                                                                />
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                                <PaginationTotalStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            </PaginationProvider>
                                            
                                        </Fragment>
                                        :
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<p>No Results</p>} />
                                    }
                                </Fragment>
                            }

                        </div>
                    }
                    </Fragment>
                }
            </Fragment>
        );
    }
}

export default withRouter(RecallHome);